interface PressListItem {
  image: string;
  url: string;
}

export const pressList: PressListItem[] = [
  {
    image: 'press1',
    url:
      'https://www.corriere.it/salute/sportello_cancro/19_marzo_27/malati-tumore-servizio-professionisti-accompagnarli-ospedale-8c99e98e-4ff9-11e9-86bf-abe38cc74ca6.shtml',
  },
  {
    image: 'press2',
    url:
      'https://stream24.ilsole24ore.com/video/italia/patologie-retina-servizio-assistenza-360-gradi/ADw1khc',
  },
  {
    image: 'press3',
    url:
      'https://www.linkiesta.it/2020/03/ugo-servizio-anziani-commissioni-coronavirus/',
  },
  {
    image: 'press4',
    url:
      'https://www.wired.it/economia/start-up/2019/10/15/startup-famiglie/?refresh_ce=',
  },
  {
    image: 'press5',
    url:
      'https://finanza.repubblica.it/News/2020/04/14/ugo_e_officinamps_ripensano_lassistenza_alle_persone_fragili-63/',
  },
];
