import React, { PropsWithChildren, useEffect, useState } from 'react';
import Footer from '../Footer/Footer';
import tw, { GlobalStyles } from 'twin.macro';
import Navbar from '../Navbar/Navbar';
import Topbar from '../Topbar';
import { ThemeProvider } from '@material-ui/core';
import { muiTheme } from '../../../../mui-theme';
import CallDriverBanner, {
  showCallDriverBannerAtom,
} from '../CallDriverBanner/CallDriverBanner';
import { atom, useAtom } from 'jotai';
import XmasBanner from '../XmasBanner/XmasBanner';

export const isLavoraConUgoPageAtom = atom(false);
export const isCalculateButtonVisibleAtom = atom(true);
export const isMobileCalculateButtonVisibleAtom = atom(true);
/**
 * Layout is being wrapped around all of the page components.
 * Check `gatsby.config.js` for wrapPageComponent method.
 * @param children
 * @constructor
 */
const Layout = ({ children, ...props }: PropsWithChildren<any>) => {
  const [scrollLocked, setScrollLocked] = useState<boolean>(false);
  const [isLavoraConUgoPage, setisLavoraConUgoPage] = useAtom(
    isLavoraConUgoPageAtom
  );
  const [isCalculateButtonVisible, setIsCalculateButtonVisible] = useAtom(
    isCalculateButtonVisibleAtom
  );
  const [
    isMobileCalculateButtonVisible,
    setIsMobileCalculateButtonVisible,
  ] = useAtom(isMobileCalculateButtonVisibleAtom);
  const [isLavoraConUgoFormPage, setIsLavoraConUgoFormPage] = useState(false);

  const duration = 0.2;

  useEffect(() => {
    const pathName = props.path;
    // Changing navbar if path is lavora-con-ugo
    setisLavoraConUgoPage(pathName.indexOf('lavora-con-ugo') > -1);
    setIsLavoraConUgoFormPage(pathName.indexOf('lavora-con-ugo-form') > -1);
    if (isCalculateButtonVisible && pathName !== '/') {
      setIsCalculateButtonVisible(false);
    }
    if (isMobileCalculateButtonVisible && pathName !== '/') {
      setIsMobileCalculateButtonVisible(false);
    }
  }, [props.path]);

  // Page content transition variants
  // const variants = {
  //   initial: {
  //     opacity: 0,
  //     y: 15,
  //   },
  //   enter: {
  //     opacity: 1,
  //     y: 0,
  //     transition: {
  //       duration: duration,
  //       delay: duration,
  //       when: 'beforeChildren',
  //       easing: 'easeOut',
  //     },
  //   },
  //   exit: {
  //     opacity: 0,
  //     y: 15,
  //     transition: { duration: duration },
  //   },
  // };

  const [showCallDriverBanner] = useAtom(showCallDriverBannerAtom);

  return (
    <ThemeProvider theme={muiTheme}>
      <div css={[tw`font-sans antialiased text-dark-500`]}>
        <GlobalStyles />
        {props?.pageContext?.pageData?.hideNavbar ? null : (
          <>
            {/*{showCallDriverBanner && !isLavoraConUgoPage ? (*/}
            {/*  <CallDriverBanner />*/}
            {/*) : (*/}
            {/*  <div></div>*/}
            {/*)}*/}
            {/*{!isLavoraConUgoPage && <Topbar />}*/}
            <Navbar mobileMenuOpen={open => setScrollLocked(!open)} />
          </>
        )}

        <main>{children}</main>
        {!isLavoraConUgoFormPage && <Footer />}
        {/*{typeof window !== 'undefined' ? (*/}
        {/*  <AnimatePresence>*/}
        {/*    {' '}*/}
        {/*    <motion.main*/}
        {/*      tw="h-full"*/}
        {/*      css={[scrollLocked && tw`overflow-hidden! h-0!`]}*/}
        {/*      key={typeof window !== 'undefined' ? location.pathname : null}*/}
        {/*      variants={variants}*/}
        {/*      initial="initial"*/}
        {/*      animate="enter"*/}
        {/*      exit="exit"*/}
        {/*    >*/}
        {/*      {children}*/}
        {/*      <Footer />*/}
        {/*    </motion.main>*/}
        {/*  </AnimatePresence>*/}
        {/*) : (*/}
        {/*  children*/}
        {/*)}*/}
      </div>
    </ThemeProvider>
  );
};

export default Layout;
