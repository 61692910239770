import { Swiper } from 'swiper/react';
import SwiperCore, { SwiperOptions, Navigation, Pagination } from 'swiper';
import styled from 'styled-components';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import tw, { TwStyle } from 'twin.macro';
import uniqueId from 'lodash/uniqueId';

SwiperCore.use([Navigation, Pagination]);

export interface SliderProps extends SwiperOptions {
  darkControls?: boolean;
  navOutside?: boolean;
  hideArrowsOnMobile?: boolean;
  hidePagination?: boolean;
  customControlsStyle?: TwStyle;
}

const StyledSwiper = styled(Swiper)<{
  $darkControls: boolean;
  $hidePagination: boolean;
}>`
  .swiper-pagination-bullet {
    background: ${({ $darkControls }) => ($darkControls ? '#a7a7a7' : '#fff')};
    opacity: 0.5;
    height: 12px;
    width: 12px;
  }
  .swiper-pagination-bullet-active {
    opacity: 1;
  }

  .swiper-padded-container {
    padding-bottom: 20px;
  }

  .swiper-pagination {
    ${props => props.$hidePagination && tw`hidden`}
  }
`;

const Slider = ({
  children,
  darkControls,
  navOutside,
  hideArrowsOnMobile,
  hidePagination = false,
  customControlsStyle,
  ...props
}: PropsWithChildren<SliderProps>) => {
  const [id, setId] = useState(null);
  useEffect(() => {
    const id = uniqueId();
    console.log(id);
    setId(id);
  }, []);

  return (
    <div tw="relative">
      <div css={[navOutside && tw`md:px-10`]}>
        <StyledSwiper
          navigation={{
            // @ts-ignore
            prevEl: props.navigation?.prevEl || '.nav-prev-' + id,
            // @ts-ignore
            nextEl: props.navigation?.nextEl || '.nav-next-' + id,
          }}
          $darkControls={darkControls}
          $hidePagination={hidePagination}
          pagination={{ clickable: true }}
          tw="relative"
          {...props}
        >
          {children}
        </StyledSwiper>
      </div>
      <svg
        /* @ts-ignore */
        className={props.navigation?.prevEl.split('.')[1] || 'nav-prev-' + id}
        height="40px"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        tw="absolute left-5 top-1/2 transform -translate-y-1/2 -translate-x-8 md:-translate-x-0 z-10 text-white cursor-pointer"
        css={[
          darkControls && tw`text-black!`,
          navOutside && tw`left-0!`,
          hideArrowsOnMobile && tw`hidden md:block`,
          customControlsStyle,
        ]}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M15 19l-7-7 7-7"
        />
      </svg>
      <svg
        /* @ts-ignore */
        className={props.navigation?.nextEl.split('.')[1] || 'nav-next-' + id}
        tw="absolute right-5 top-1/2 transform -translate-y-1/2 z-10 translate-x-8 md:translate-x-0 text-white cursor-pointer"
        css={[
          darkControls && tw`text-black!`,
          navOutside && tw`right-0!`,
          hideArrowsOnMobile && tw`hidden md:block`,
          customControlsStyle,
        ]}
        height="40px"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 5l7 7-7 7"
        />
      </svg>
    </div>
  );
};
export default Slider;
