import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { SwiperOptions, Navigation, Pagination } from 'swiper';
import { Link } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { BlogPageQuery } from '../../../../generated/graphql-types';
import Heading from '../../core/typography/Heading';
import Button from '../../core/buttons/Button';
import styled from 'styled-components';
import tw from 'twin.macro';

const swiperOptions: SwiperOptions = {
  loop: true,
  autoplay: {
    delay: 7500,
  },
};

SwiperCore.use([Navigation, Pagination]);

export const StyledSwiper = styled(Swiper)`
  .swiper-pagination-bullet {
    background: #fff;
    opacity: 0.5;
    height: 12px;
    width: 12px;
  }
  .swiper-pagination-bullet-active {
    opacity: 1;
  }
`;

interface BlogPageHeaderSliderProps {
  blogs: BlogPageQuery['blogs']['nodes'];
  rounded?: boolean;
}

const BlogSlider = ({ blogs, rounded = false }: BlogPageHeaderSliderProps) => {
  return (
    <div tw="relative overflow-hidden" css={[rounded && tw`rounded-md`]}>
      <StyledSwiper
        {...swiperOptions}
        navigation={{
          prevEl: '.nav-prev-blog',
          nextEl: '.nav-next-blog',
        }}
        pagination={{ clickable: true }}
        tw="relative"
      >
        {blogs.slice(0, 5).map(b => (
          <SwiperSlide key={b.slug} style={{ height: 'auto' }}>
            <BackgroundImage
              fluid={b.image.fluid}
              tw="flex flex-col"
              style={{ height: '100%', minHeight: '500px' }}
            >
              <div
                style={{ height: '100%' }}
                tw="bg-black bg-opacity-60 flex-1 flex items-center justify-center py-20"
              >
                <div tw="w-full md:w-3/4 lg:w-1/2 text-center px-16 lg:px-0">
                  <Heading variant="h3" tw="text-white!">
                    {b.title}
                  </Heading>
                  <p tw="text-paragraph text-white! text-center mb-8">
                    {b.summary}
                  </p>
                  <Link to={`/blog/${b.slug}/`}>
                    <Button variant="filled" color="primary" tw="px-20!">
                      Leggi di più
                    </Button>
                  </Link>
                </div>
              </div>
            </BackgroundImage>
          </SwiperSlide>
        ))}
      </StyledSwiper>
      <svg
        className="nav-prev-blog"
        height="40px"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        tw="absolute left-5 top-1/2 transform -translate-y-1/2 z-10 text-white cursor-pointer"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M15 19l-7-7 7-7"
        />
      </svg>
      <svg
        className="nav-next-blog"
        tw="absolute right-5 top-1/2 transform -translate-y-1/2 z-10 text-white cursor-pointer"
        height="40px"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 5l7 7-7 7"
        />
      </svg>
    </div>
  );
};

export default BlogSlider;
