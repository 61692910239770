import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import CityItem from '../../views/CityItem/CityItem';
import { CitiesQuery } from '../../../../generated/graphql-types';
import { FluidObject } from 'gatsby-image';
import 'twin.macro';
import Heading from '../../core/typography/Heading';
/**
 * If required, create a seperate component and make this one reusable with city list
 */

const CityList = () => {
  const cities = useStaticQuery<CitiesQuery>(graphql`
    query Cities {
      cities: allContentfulCity {
        nodes {
          shortName
          metaTitle
          content1 {
            raw
          }
          content2 {
            raw
          }
          content3 {
            raw
          }
          content4 {
            raw
          }
          content6 {
            raw
          }
          content7 {
            raw
          }
          gallery1 {
            title
            fluid {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
          gallery2 {
            title
            fluid {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
          mainImage {
            fluid {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
          slug
          name
          metaDescription
          isDummy
        }
      }
    }
  `);

  return (
    <div>
      <Heading tw="mb-10" variant="h4">
        In quali città puoi trovare UGO
      </Heading>
      <div tw="flex flex-wrap -mx-4">
        {cities.cities.nodes
          .filter(c => !c.isDummy)
          .map(c => (
            <div
              tw="w-1/2 md:w-1/3 xl:w-1/5 p-4 transform hover:scale-105 transition-transform duration-200 ease-in-out"
              key={c.slug}
            >
              <Link to={`/${c.slug}/`}>
                <CityItem image={c.mainImage.fluid} name={c.shortName} />
              </Link>
            </div>
          ))}
      </div>
    </div>
  );
};

export default CityList;
