import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '../Accordion/Accordion';
import Heading from '../../core/typography/Heading';
import React, { ReactNode, useState } from 'react';
import 'twin.macro';

export interface FaqItem {
  question: string;
  answer: string | ReactNode;
}

interface FaqAccordionProps {
  faqItems: FaqItem[];
  openFirst?: boolean;
}

const FaqAccordion = (props: FaqAccordionProps) => {
  const [expanded, setExpanded] = useState<string | false>(
    props.openFirst ? 'panel0' : false
  );

  const handleChange = (panel: string) => (event: React.ChangeEvent<any>) => {
    setExpanded(panel !== expanded ? panel : false);
    return event;
  };
  return (
    <div>
      {props.faqItems.map((f, i) => (
        <Accordion
          key={f.question}
          square
          expanded={expanded === 'panel' + i}
          onChange={handleChange('panel' + i)}
        >
          <AccordionSummary
            tw="bg-gray-100!"
            expandIcon={
              <div tw="text-dark-500 rounded-max bg-primary-500 p-1">
                <svg
                  height="20px"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </div>
            }
          >
            <Heading variant="h6" tw="mb-0">
              {f.question}
            </Heading>
          </AccordionSummary>
          <AccordionDetails>
            {typeof f.answer === 'string' ? (
              <p tw="text-paragraph">{f.answer}</p>
            ) : (
              <>{f.answer}</>
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default FaqAccordion;
