import React, { useEffect, useRef, useState } from 'react';
import { atom, useAtom } from 'jotai';
import { Container } from '../Layout';
import { atomWithStorage } from 'jotai/utils';
import { Link } from 'gatsby';
import tw from 'twin.macro';
import useDatalayer from '../../../../../hooks/use-datalayer';
import { useAuth } from '../../../../../hooks/use-auth';
import { isBrowser } from '../../../../pages';

export const showCallDriverBannerAtom = atomWithStorage(
  'show_call_driver_banner_user',
  true
);

export const driverBannerHeightAtom = atom(0);

const CallDriverBanner = () => {
  const [showCallDriverBanner, setShowCallDriverBanner] = useAtom(
    showCallDriverBannerAtom
  );
  const [, setBannerHeight] = useAtom(driverBannerHeightAtom);
  const [hideBanner, setHideBanner] = useState(false);
  const bannerRef = useRef();

  let auth;
  if (isBrowser) {
    auth = useAuth();
  }

  useEffect(() => {
    if (bannerRef.current) {
      // @ts-ignore
      setBannerHeight(bannerRef?.current?.clientHeight || 0);
    } else {
      setBannerHeight(0);
    }
    return () => setBannerHeight(0);
  }, [bannerRef.current, showCallDriverBanner]);

  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    setShowBanner(true);
  }, []);

  const datalayer = useDatalayer();

  const handleHideBanner = () => {
    setShowBanner(false);
    setBannerHeight(0);

    if (auth?.user) {
      setShowCallDriverBanner(false);
    }
  };

  return (
    <div
      ref={bannerRef}
      id="driver-banner"
      className="no-before"
      css={[
        tw`bg-secondary-500 text-white relative sticky top-0 z-20 shadow-md block`,
      ]}
    >
      {showBanner && showCallDriverBanner ? (
        <Container>
          <div tw="py-4">
            <p tw="hidden lg:block text-center px-10">
              Vuoi provare il servizio, ma prima vorresti parlare con noi?&nbsp;
              <a
                target="_blank"
                tw="font-bold underline"
                href="https://hellougo.typeform.com/to/SqssJETX?utm_content=greenbar&typeform-source=hellougo.com"
              >
                Clicca qui per prenotare la chiamata!
              </a>
            </p>
            <p tw="lg:hidden font-bold pr-6">
              Vuoi conoscerci prima di provare UGO? <br />
              Cosa aspetti?!&nbsp;
              <a
                target="_blank"
                tw="font-bold underline"
                href="https://hellougo.typeform.com/to/SqssJETX?utm_content=greenbar&typeform-source=hellougo.com"
              >
                Prenota la chiamata.
              </a>
            </p>
            <div
              onClick={handleHideBanner}
              tw="absolute right-3 top-1/2 transform scale-75 md:scale-100 -translate-y-1/2 cursor-pointer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className=""
                fill="none"
                height="35px"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
          </div>
        </Container>
      ) : null}
    </div>
  );
};

export default CallDriverBanner;
