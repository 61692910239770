import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';

export const Accordion = withStyles({
  root: {
    background: '#fff !important',
    borderRadius: '18px',
    boxShadow: '0 0 16px 8px rgba(20, 38, 85, 0.05)',
    marginBottom: 22,
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
      marginBottom: 22,
    },
  },
  expanded: {
    marginBottom: 22,
  },
})(MuiAccordion);

export const AccordionSummary = withStyles({
  root: {
    background: '#fff !important',
    marginBottom: -1,
    borderRadius: '18px',
    minHeight: 70,
    borderBottomRightRadius: '18px',
    borderBottomLeftRadius: '18px',
    '&$expanded': {
      minHeight: 70,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

export const AccordionDetails = withStyles(theme => ({
  root: {
    display: 'block !important',
    borderBottomRightRadius: '18px',
    borderBottomLeftRadius: '18px',
    background: '#fff !important',
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);
