import React from 'react';
import 'twin.macro';
import BackgroundImage from 'gatsby-background-image';
import { graphql, useStaticQuery } from 'gatsby';
import { operators } from './operators-data';
import { OperatorsSliderQuery } from '../../../../generated/graphql-types';
import { SwiperOptions } from 'swiper';
import { SwiperSlide } from 'swiper/react';
import Slider from '../../views/Slider/Slider';

const swiperOptions: SwiperOptions = {
  loop: true,
  breakpoints: {
    0: {
      slidesPerView: 1,
      spaceBetween: 32,
    },
    640: {
      slidesPerView: 2,
      spaceBetween: 32,
    },
    1360: {
      slidesPerView: 3,
      spaceBetween: 32,
    },
  },
  autoplay: {
    delay: 7500,
  },
};

const OperatorsSlider = () => {
  const operatorsImages = useStaticQuery<OperatorsSliderQuery>(graphql`
    query OperatorsSlider {
      francesca: file(relativePath: { eq: "operators/francesca.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluidFragment
          }
        }
      }
      fernando: file(relativePath: { eq: "operators/fernando.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluidFragment
          }
        }
      }
      simona: file(relativePath: { eq: "operators/simona.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluidFragment
          }
        }
      }
      sonia: file(relativePath: { eq: "operators/sonia.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluidFragment
          }
        }
      }
      giacomo: file(relativePath: { eq: "operators/giacomo.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluidFragment
          }
        }
      }
      mattia: file(relativePath: { eq: "operators/mattia.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluidFragment
          }
        }
      }
      gianni: file(relativePath: { eq: "operators/gianni.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluidFragment
          }
        }
      }
      massimiliano: file(relativePath: { eq: "operators/massimiliano.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluidFragment
          }
        }
      }
      gabriele: file(relativePath: { eq: "operators/gabriele.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluidFragment
          }
        }
      }
    }
  `);

  return (
    <div tw="relative overflow-hidden">
      <Slider
        darkControls
        navOutside
        {...swiperOptions}
        pagination={{ clickable: true }}
        tw="relative"
      >
        {operators.map(o => (
          <SwiperSlide key={o.key} style={{ height: 'auto' }}>
            <div style={{ height: '100%' }} tw="px-2 flex pb-20">
              <div tw="rounded-card shadow-md overflow-hidden">
                <BackgroundImage
                  fluid={operatorsImages[o.key].childImageSharp.fluid}
                  tw="flex flex-col"
                  style={{ height: '200px' }}
                />
                <div tw="p-4">
                  <p tw="text-paragraph mb-8">{o.title}</p>
                  <p tw="text-paragraph mb-8">{o.summary}</p>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Slider>
    </div>
  );
};

export default OperatorsSlider;
