import React from 'react';
import { PropsWithChildren } from 'react';
import { CSSProp } from 'styled-components';
import 'twin.macro';
import tw, { css } from 'twin.macro';

interface HeadingProps {
  variant: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  css?: CSSProp;
  id?: string;
}

const headingStyles = css`
  ${tw`text-dark-500 font-bold`}
`;

const Heading = ({ id, ...props }: PropsWithChildren<HeadingProps>) => {
  switch (props.variant) {
    case 'h1': {
      return (
        <h1
          css={[tw`text-h1-mobile md:text-h1 tracking-h1 mb-8 break-words`, headingStyles]}
          id={id}
          {...props}
        >
          {props.children}
        </h1>
      );
    }
    case 'h2': {
      return (
        <h2
          css={[tw`text-h2-mobile md:text-h2 tracking-h2 mb-8`, headingStyles]}
          id={id}
          {...props}
        >
          {props.children}
        </h2>
      );
    }
    case 'h3': {
      return (
        <h3
          css={[tw`text-h3-mobile md:text-h3 tracking-h3 mb-8`, headingStyles]}
          id={id}
          {...props}
        >
          {props.children}
        </h3>
      );
    }
    case 'h4': {
      return (
        <h4
          css={[tw`text-h4-mobile md:text-h4 tracking-h4 mb-4`, headingStyles]}
          id={id}
          {...props}
        >
          {props.children}
        </h4>
      );
    }
    case 'h5': {
      return (
        <h5
          css={[tw`text-h5-mobile md:text-h5 tracking-h5 mb-4`, headingStyles]}
          id={id}
          {...props}
        >
          {props.children}
        </h5>
      );
    }
    case 'h6': {
      return (
        <h6
          css={[tw`text-h6-mobile md:text-h6 tracking-h6 mb-3`, headingStyles]}
          id={id}
          {...props}
        >
          {props.children}
        </h6>
      );
    }
  }
};

export default Heading;
