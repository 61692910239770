import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { PropsWithChildren, useEffect } from 'react';
import { gql } from 'apollo-boost';
import { useAuth } from '../../../hooks/use-auth';
import 'twin.macro';
import Cookie from 'js-cookie';

const SIGN_IN_MUTATION = gql`
  mutation checkAuthStatus($token: String!) {
    checkAuthStatus(token: $token) {
      token
    }
  }
`;

const Auth = ({ children }: PropsWithChildren<any>) => {
  const [mutation] = useMutation(SIGN_IN_MUTATION);
  const [csst, setCsst] = useState();
  const auth = useAuth();

  const loginWithToken = async token => {
    const response = await mutation({
      variables: { token },
    });
    await auth.signInWithCustomToken(response.data.checkAuthStatus.token);
  };

  // Cookie checking
  useEffect(() => {
    const checkCookie = (function () {
      return function () {
        setCsst(Cookie.get('csst'));
      };
    })();

    const interval = window.setInterval(checkCookie, 1000);

    return () => window.clearInterval(interval);
  }, []);

  useEffect(() => {
    if (csst && !auth.user) {
      loginWithToken(csst);
    } else if (auth.user && !csst) {
      // In case cookies were cleared, we sign user out
      auth.signOut();
    }
  }, [csst, auth.user]);

  const isBrowserInStaging =
    typeof location !== 'undefined' &&
    (location?.href?.indexOf('staging') > -1 ||
      location?.href?.indexOf('localhost') > -1);

  return (
    <>
      {children}{' '}
      {isBrowserInStaging ? (
        <div tw="p-2 text-sm bg-error-500 text-white fixed z-20 top-0 right-0">
          User: {auth?.user?.email || 'no user'}
        </div>
      ) : null}
    </>
  );
};

export default Auth;
