import React, { ReactNode } from 'react';

export interface ServiceItem {
  title: string;
  // Image string will refer to the item inside image query of OurServices component
  image: string;
  description: string | ReactNode;
  features: string[];
  url: string;
}

export const serviceItems: ServiceItem[] = [
  {
    image: 'accompagnamento',
    title: 'Accompagnamento',
    description: (
      <>
        A piedi, con la nostra auto o con la tua ti supportiamo a 360°: per
        andare <b>a fare una visita medica o per una passeggiata</b>, puoi
        contare sui nostri accompagnatori. Tutto diventa più semplice se c’è il
        supporto fisico e morale di un Caregiver UGO.
      </>
    ),
    features: [
      'A piedi',
      'Con i mezzi pubblici, in taxi, NCC',
      'Con la macchina dell’utente',
      'Con la macchina di UGO',
    ],
    url: '/servizio-accompagnamento-anziani/',
  },
  {
    image: 'commissioni',
    title: 'Commissioni',
    description: (
      <>
        Quando hai bisogno di <b>delegare piccole commissioni quotidiane</b>{' '}
        puoi fare affidamento su di noi. I nostri Caregiver ti ascoltano e
        prendono in carico ogni tua indicazione in maniera scrupolosa. Qualsiasi
        commissione è a portata di UGO: basta chiedere!
      </>
    ),
    features: [
      'Ritirare farmaci e ricette mediche',
      'Pagare le bollette ',
      'Fare la spesa',
      'Portare fuori il cane e tanto altro!',
    ],
    url: '/spesa-e-commissioni-domicilio-anziani/',
  },
  {
    image: 'compagnia',
    title: 'Compagnia',
    description: (
      <>
        <b>Scambiare racconti, ascoltarsi e supportarsi:</b> questo è il nostro
        servizio di <b>compagnia</b> che permette a tanti anziani di non
        sentirsi soli. I Caregiver professionali UGO diventano un riferimento,
        figure amicali che insieme a te possono:
      </>
    ),
    features: [
      'Chiacchierare al telefono',
      'Fare una partita a carte',
      'Fare una passeggiata sotto casa',
      'Visitare mostre e attività culturali',
    ],
    url: '/compagnia-anziani/',
  },
];
