interface PartnersListItem {
  image: string;
  url: string;
  alt: string;
}

/*
 * Partners list is used to map queried logos with urls from partners web sites
 */
export const partners: PartnersListItem[] = [
  {
    image: 'partner1',
    url:
      'https://www.amgen.it/it-it/sala-stampa/featured-news/2020/03/restaacasa-alla-spesa-e-ai-farmaci-pensano-ugo-e-amgen/',
    alt: 'amgen',
  },
  {
    image: 'partner2',
    url:
      'https://www.humanitas.it/news/ugo-un-servizio-accompagnamento-pazienti-convenzionato-humanitas',
    alt: 'humanitas',
  },
  {
    image: 'partner3',
    url: 'https://www.ieo.it/it/PNA/Lista-News/Servizio-accompagnamento-UGO/',
    alt: 'ieo',
  },
  {
    image: 'partner4',
    url: 'https://www.multimedica.it/informazioni-utili/ugo-gruppomultimedica',
    alt: 'multimedica',
  },
  {
    image: 'partner5',
    url: 'https://www.roche.it/',
    alt: 'roche',
  },
  {
    image: 'partner6',
    url:
      'https://www.santagostino.it/it/news/ugo-ti-accompagna-al-santagostino',
    alt: 'santagostino',
  },
  {
    image: 'partner7',
    url:
      'http://www.insiemeconhumanitas.it/iorestoacasa-ugo-servizi-gratuiti-over-65-pazienti-fragili/',
    alt: 'insiemeconhumanitas',
  },
  {
    image: 'partner8',
    url:
      'https://www.villagecare.it/accompagnamento-per-anziani-e-disabili-milano/',
    alt: 'villagecare',
  },
  {
    image: 'partner9',
    url: 'https://www.medicinfamiglia.it/elenco_convenzioni/ugo/',
    alt: 'medicinfamiglia',
  },
  {
    image: 'partner10',
    url:
      'https://ant.it/cosa-facciamo/assistenza/personal-caregiver-ugo-milano/',
    alt: 'ant',
  },
  {
    image: 'partner11',
    url:
      'https://www.assinews.it/12/2019/axa-italia-completa-lofferta-salute-la-startup-ugo/660069557/',
    alt: 'assinews',
  },
  {
    image: 'partner12',
    url:
      'https://www.telecomitalia.com/tit/it/archivio/media/note-stampa/corporate/2020/CS-TIM-al-fianco-del-Comune-di-Torino-13-marzo-2020.html',
    alt: 'telecomitalia',
  },
  {
    image: 'partner13',
    url:
      'https://www.officina.mps.it/restaacasa-officinamps-porta-ugo-in-toscana.html',
    alt: 'officina',
  },
  {
    image: 'partner14',
    url: 'https://www.europassistance.it/',
    alt: 'europassistance',
  },
  {
    image: 'partner15',
    url:
      'https://www.jointly.pro/2018/10/02/nonni-sprint-e-perno-del-welfare-italiano-per-la-festa-nazionale-dei-nonni-il-nostro-partner-ugo-li-festeggia-con-una-piccola-sorpresa/',
    alt: 'jointly',
  },
  {
    image: 'partner16',
    url:
      'https://stream24.ilsole24ore.com/video/italia/patologie-retina-servizio-assistenza-360-gradi/ADw1khc',
    alt: 'ilsole24ore',
  },
  {
    image: 'partner17',
    url:
      'https://www.affaritaliani.it/economia/officina-mps-vince-ugo-premio-speciale-sostenibilita-alla-startup-biorfarm-606044.html',
    alt: 'affaritaliani',
  },
  {
    image: 'partner18',
    url:
      'https://www.wcap.tim.it/it/2020/03/coronavirus-tim-e-le-startup-tim-wcap-insieme-l%E2%80%99emergenza',
    alt: 'wcap',
  },
  {
    image: 'partner20',
    url: 'http://www.ospedalesanmartino.it/',
    alt: 'ospedalesanmartino',
  },
  {
    image: 'partner21',
    url: 'https://www.cardiologicomonzino.it/it/monzino-viaggiare-facile/',
    alt: 'cardiologicomonzino',
  },
  {
    image: 'partner22',
    url: 'https://www.federfarmalombardia.it/',
    alt: 'federfarmalombardia',
  },
  {
    image: 'partner23',
    url: 'https://www.studioterapiebariana.com/',
    alt: 'studioterapiebariana',
  },
  {
    image: 'partner24',
    url: 'https://www.amolavitaonlus.it/',
    alt: 'amolavitaonlus',
  },
  {
    image: 'partner25',
    url:
      'http://www.asmemilano.it/progetto-di-accompagnamento-in-ospedale-denominato-ugo/',
    alt: 'asmemilano',
  },
  {
    image: 'partner26',
    url: 'https://www.parkinson-lombardia.it/',
    alt: 'parkinson-lombardia',
  },
  {
    image: 'partner27',
    url:
      'https://blog.ausilium.it/hellougo-il-servizio-che-aiuta-le-famiglie-nellassistenza-di-anziani-e-disabili/',
    alt: 'ausilium',
  },
  {
    image: 'partner28',
    url: 'https://www.fightthestroke.org/',
    alt: 'fightthestroke',
  },
  {
    image: 'partner29',
    url: 'https://spesasospesa.org/',
    alt: 'spesasospesa',
  },
  {
    image: 'partner30',
    url: 'http://www.camminoinsalute.com/',
    alt: 'camminoinsalute',
  },
  {
    image: 'partner31',
    url: 'https://www.coopeureka.it/',
    alt: 'coopeureka',
  },
  {
    image: 'partner32',
    url: 'https://www.flixbus.it/',
    alt: 'flixbus',
  },
  {
    image: 'partner33',
    url: 'https://www.edenred.it/welfare-aziendale/',
    alt: 'edenred',
  },
  {
    image: 'partner34',
    url:
      'https://www.cortivo.it/testimonianze/intervista-a-michela-conti-di-hellougo-com/',
    alt: 'cortivo',
  },
  {
    image: 'partner35',
    url:
      'https://www.auxologico.it/news/ugo-servizio-accompagnamento-pazienti-autonomi',
    alt: 'auxologico',
  },
  {
    image: 'partner36',
    url:
      'https://www.santagostino.it/it/news/ugo-ti-accompagna-al-santagostino',
    alt: 'c_salute',
  },
  {
    image: 'partner37',
    url: 'https://it.gsk.com/it-it/home/',
    alt: 'gsk',
  },
  {
    image: 'partner38',
    url: 'https://www.dentalpro.it/convenzioni-ugo.html',
    alt: 'Orizzontale_CentriDentistici',
  },
  {
    image: 'partner39',
    url: '#',
    alt: 'https://www.grupposandonato.it/',
  },
  {
    image: 'partner40',
    url: 'http://www.polisantangela.it/',
    alt: 'sant_angela',
  },
  {
    image: 'partner41',
    url: 'https://www.fiscozen.it/',
    alt: 'fiscozen',
  },
  {
    image: 'partner42',
    url: 'https://www.amplifon.com/',
    alt: 'amplifon',
  },
  {
    image: 'partner44',
    url: 'https://www.safacli.com/',
    alt: 'safacli',
  },
  {
    image: 'partner43',
    url: 'https://www.centrofisioterapicofilippini.it/',
    alt: 'centrofisioterapicofilippini',
  },
  {
    image: 'partner45',
    url: 'https://medihouse.it/',
    alt: 'medihouse',
  },
  {
    image: 'partner46',
    url: 'https://www.skydental3d.it/',
    alt: 'skydental3d',
  },
  {
    image: 'partner47',
    url: 'https://www.diagnosticamedicaveloce.it/',
    alt: 'diagnosticamedicaveloce',
  },
  {
    image: 'partner48',
    url: null,
    alt: 'asem',
  },
  {
    image: 'partner49',
    url: 'https://www.uicibologna.it/',
    alt: 'uicibologna',
  },
  {
    image: 'partner50',
    url: 'https://www.cpdconsulta.it/',
    alt: 'cpdconsulta',
  },
  {
    image: 'partner51',
    url: 'https://phdlifescience.eu/',
    alt: 'hnp',
  },
  {
    image: 'partner52',
    url: 'https://lotonlus.org/',
    alt: 'loto',
  },
  {
    image: 'partner53',
    url: 'https://www.officinebuone.it/',
    alt: 'officinebuone',
  },
  {
    image: 'partner54',
    url: 'https://www.europassistance.it/',
    alt: 'europassistance',
  },
  {
    image: 'partner55',
    url: 'https://www.internationalcarecompany.com/',
    alt: 'icc',
  },
  {
    image: 'partner56',
    url: 'https://www.ip-assistance.dz/en',
    alt: 'interpartnerassistance',
  },
  {
    image: 'partner57',
    url: 'https://www.aon.com/italy/default.jsp',
    alt: 'aon',
  },
  {
    image: 'partner58',
    url:
      'https://www.jointly.pro/2018/10/02/nonni-sprint-e-perno-del-welfare-italiano-per-la-festa-nazionale-dei-nonni-il-nostro-partner-ugo-li-festeggia-con-una-piccola-sorpresa/',
    alt: 'jointly',
  },
  {
    image: 'partner59',
    url: 'https://www.gruppopellegrini.it/',
    alt: 'pellegrini',
  },
  {
    image: 'partner60',
    url: 'https://www.randstad.it/',
    alt: 'randstad',
  },
  {
    image: 'partner61',
    url: 'https://www.day.it/welfare-aziendale',
    alt: 'daywelfare',
  },
  {
    image: 'partner62',
    url: 'https://www.comune.milano.it/',
    alt: 'milanoaiuta',
  },
  {
    image: 'partner63',
    url: 'https://www.comune.fi.it/',
    alt: 'comunedifirenze',
  },
  {
    image: 'partner64',
    url: 'https://www.coop.it/',
    alt: 'coop',
  },
  {
    image: 'partner65',
    url: 'https://www.cdp.it/sitointernet/en/homepage.page',
    alt: 'cdp',
  },
  {
    image: 'partner66',
    url: 'https://www.farmaciecomunalifirenze.it/',
    alt: 'farmaciecomunalifirenze',
  },
  {
    image: 'partner67',
    url: 'https://www.insiemeconhumanitas.it/',
    alt: 'insiemeconhumanitas',
  },
  {
    image: 'partner68',
    url: 'https://www.otoacusticalombarda.it/',
    alt: 'otoacusticalombarda',
  },
  {
    image: 'partner69',
    url: 'https://www.fatebenefratelli-isolatiberina.it/',
    alt: 'ospedaleisolatiberina',
  },
];
