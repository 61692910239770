const FORM_URL =
  'https://af97095e.sibforms.com/serve/MUIEAMe_R32eYd-VehK9yeqrmjjqkDS0rW13S4UfPFfkc5VwEtC59SQSlFGi3lPHzshcio5PTr1_U7CIRfr9zktB9uj8F8BVMfumgtrHgIDNG_hSZc0TFtDC0LItEZbgR8dM97NePZJolv5X7BdrlN6GjMBsWpTxi8riB3a_EH4I2qtFXdMRezoAUs6U5j9fJdju9q6KuD5zwrsd';

export const subscribeToNewsletter = (data: { email: string }) => {
  const formData = new FormData();
  formData.append('EMAIL', data.email);

  const requestOptions: RequestInit = {
    method: 'POST',
    mode: 'no-cors',
    body: formData,
  };

  return fetch(FORM_URL, requestOptions);
};
