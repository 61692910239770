import { Block, BLOCKS, Inline, INLINES } from '@contentful/rich-text-types';
import React, { ReactNode } from 'react';
import 'twin.macro';
import Image from 'gatsby-image';
import ListItem from '../components/core/lists/ListItem';
import List from '../components/core/lists/List';
import Heading from '../components/core/typography/Heading';

export const renderOptions = {
  renderNode: {
    [BLOCKS.HEADING_1]: (node: Block | Inline, children: ReactNode) => {
      return (
        <Heading variant="h1" tw="mt-10">
          {children}
        </Heading>
      );
    },
    [BLOCKS.HEADING_2]: (node: Block | Inline, children: ReactNode) => {
      return (
        <Heading variant="h2" tw="mt-10">
          {children}
        </Heading>
      );
    },
    [BLOCKS.HEADING_3]: (node: Block | Inline, children: ReactNode) => {
      return (
        <Heading variant="h3" tw="mt-10">
          {children}
        </Heading>
      );
    },
    [BLOCKS.HEADING_4]: (node: Block | Inline, children: ReactNode) => {
      return (
        <Heading variant="h4" tw="mt-4">
          {children}
        </Heading>
      );
    },
    [BLOCKS.HEADING_5]: (node: Block | Inline, children: ReactNode) => {
      return (
        <Heading variant="h5" tw="mt-4">
          {children}
        </Heading>
      );
    },
    [BLOCKS.HEADING_6]: (node: Block | Inline, children: ReactNode) => {
      return (
        <Heading variant="h6" tw="mt-3">
          {children}
        </Heading>
      );
    },
    [BLOCKS.PARAGRAPH]: (node: Block | Inline, children: ReactNode) => {
      return <p tw="text-paragraph mb-4">{children}</p>;
    },
    [BLOCKS.UL_LIST]: (node: Block | Inline, children: ReactNode) => {
      return <List>{children}</List>;
    },
    [BLOCKS.LIST_ITEM]: (node: Block | Inline, children: ReactNode) => {
      return <ListItem tw="-mt-4 first:mt-0">{children}</ListItem>;
    },
    [INLINES.HYPERLINK]: (node: Block | Inline, children: ReactNode) => {
      return (
        <a tw="text-primary-500 font-bold" href={node.data.uri}>
          {children}
        </a>
      );
    },
    [BLOCKS.EMBEDDED_ASSET]: (node: Block | Inline) => {
      return (
        <Image
          tw="my-10"
          alt={node.data.target.title || node.data.contentful_id}
          style={{ maxHeight: '400px' }}
          imgStyle={{ objectFit: 'contain' }}
          {...node.data.target}
        />
      );
    },
  },
  renderText: (text: string) =>
    text.split('\\n').flatMap((text, i) => [i > 0 && <br />, text]),
};

export const renderOptionsLowMarginHeaders = {
  renderNode: {
    [BLOCKS.HEADING_1]: (node: Block | Inline, children: ReactNode) => {
      return (
        <Heading variant="h1" tw="mb-4">
          {children}
        </Heading>
      );
    },
    [BLOCKS.HEADING_2]: (node: Block | Inline, children: ReactNode) => {
      return (
        <Heading variant="h2" tw="mb-4">
          {children}
        </Heading>
      );
    },
    [BLOCKS.HEADING_3]: (node: Block | Inline, children: ReactNode) => {
      return (
        <Heading variant="h3" tw="mb-4">
          {children}
        </Heading>
      );
    },
    [BLOCKS.HEADING_4]: (node: Block | Inline, children: ReactNode) => {
      return (
        <Heading variant="h4" tw="mb-4">
          {children}
        </Heading>
      );
    },
    [BLOCKS.HEADING_5]: (node: Block | Inline, children: ReactNode) => {
      return (
        <Heading variant="h5" tw="mb-4">
          {children}
        </Heading>
      );
    },
    [BLOCKS.HEADING_6]: (node: Block | Inline, children: ReactNode) => {
      return (
        <Heading variant="h6" tw="mb-4">
          {children}
        </Heading>
      );
    },
    [BLOCKS.PARAGRAPH]: (node: Block | Inline, children: ReactNode) => {
      return <p tw="text-paragraph mb-4">{children}</p>;
    },
    [BLOCKS.UL_LIST]: (node: Block | Inline, children: ReactNode) => {
      return <List>{children}</List>;
    },
    [BLOCKS.LIST_ITEM]: (node: Block | Inline, children: ReactNode) => {
      return <ListItem tw="-mt-4 first:mt-0">{children}</ListItem>;
    },
    [INLINES.HYPERLINK]: (node: Block | Inline, children: ReactNode) => {
      return (
        <a tw="text-primary-500 font-bold" href={node.data.uri}>
          {children}
        </a>
      );
    },
    [BLOCKS.EMBEDDED_ASSET]: (node: Block | Inline) => {
      return (
        <Image
          tw="my-10"
          alt={node.data.target.title || node.data.contentful_id}
          style={{ maxHeight: '400px' }}
          imgStyle={{ objectFit: 'contain' }}
          {...node.data.target}
        />
      );
    },
  },
  renderText: (text: string) =>
    text.split('\\n').flatMap((text, i) => [i > 0 && <br />, text]),
};
