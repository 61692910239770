// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-templates-blog-page-tsx": () => import("./../../../src/components/templates/blog-page.tsx" /* webpackChunkName: "component---src-components-templates-blog-page-tsx" */),
  "component---src-components-templates-city-page-tsx": () => import("./../../../src/components/templates/city-page.tsx" /* webpackChunkName: "component---src-components-templates-city-page-tsx" */),
  "component---src-components-templates-layout-1-tsx": () => import("./../../../src/components/templates/layout-1.tsx" /* webpackChunkName: "component---src-components-templates-layout-1-tsx" */),
  "component---src-components-templates-promotion-page-tsx": () => import("./../../../src/components/templates/promotion-page.tsx" /* webpackChunkName: "component---src-components-templates-promotion-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-accompagnamento-anziani-indennita-tsx": () => import("./../../../src/pages/accompagnamento-anziani-indennita.tsx" /* webpackChunkName: "component---src-pages-accompagnamento-anziani-indennita-tsx" */),
  "component---src-pages-accompagnamento-indennita-tsx": () => import("./../../../src/pages/accompagnamento-indennita.tsx" /* webpackChunkName: "component---src-pages-accompagnamento-indennita-tsx" */),
  "component---src-pages-accompagnatori-tsx": () => import("./../../../src/pages/accompagnatori.tsx" /* webpackChunkName: "component---src-pages-accompagnatori-tsx" */),
  "component---src-pages-assicurazioni-tsx": () => import("./../../../src/pages/assicurazioni.tsx" /* webpackChunkName: "component---src-pages-assicurazioni-tsx" */),
  "component---src-pages-aziende-farmaceutiche-tsx": () => import("./../../../src/pages/aziende-farmaceutiche.tsx" /* webpackChunkName: "component---src-pages-aziende-farmaceutiche-tsx" */),
  "component---src-pages-aziende-tsx": () => import("./../../../src/pages/aziende.tsx" /* webpackChunkName: "component---src-pages-aziende-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-cookie-policy-tsx": () => import("./../../../src/pages/cookie-policy.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-tsx" */),
  "component---src-pages-domande-frequenti-tsx": () => import("./../../../src/pages/domande-frequenti.tsx" /* webpackChunkName: "component---src-pages-domande-frequenti-tsx" */),
  "component---src-pages-i-partner-di-ugo-tsx": () => import("./../../../src/pages/i-partner-di-ugo.tsx" /* webpackChunkName: "component---src-pages-i-partner-di-ugo-tsx" */),
  "component---src-pages-il-servizio-tsx": () => import("./../../../src/pages/il-servizio.tsx" /* webpackChunkName: "component---src-pages-il-servizio-tsx" */),
  "component---src-pages-impatto-tsx": () => import("./../../../src/pages/impatto.tsx" /* webpackChunkName: "component---src-pages-impatto-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lacuranonsiferma-tsx": () => import("./../../../src/pages/lacuranonsiferma.tsx" /* webpackChunkName: "component---src-pages-lacuranonsiferma-tsx" */),
  "component---src-pages-lavora-con-ugo-form-tsx": () => import("./../../../src/pages/lavora-con-ugo-form.tsx" /* webpackChunkName: "component---src-pages-lavora-con-ugo-form-tsx" */),
  "component---src-pages-lavora-con-ugo-tsx": () => import("./../../../src/pages/lavora-con-ugo.tsx" /* webpackChunkName: "component---src-pages-lavora-con-ugo-tsx" */),
  "component---src-pages-prezzi-accompagnamento-tsx": () => import("./../../../src/pages/prezzi-accompagnamento.tsx" /* webpackChunkName: "component---src-pages-prezzi-accompagnamento-tsx" */),
  "component---src-pages-prezzi-commissioni-tsx": () => import("./../../../src/pages/prezzi-commissioni.tsx" /* webpackChunkName: "component---src-pages-prezzi-commissioni-tsx" */),
  "component---src-pages-prezzi-compagnia-tsx": () => import("./../../../src/pages/prezzi-compagnia.tsx" /* webpackChunkName: "component---src-pages-prezzi-compagnia-tsx" */),
  "component---src-pages-privacy-e-cookie-policy-tsx": () => import("./../../../src/pages/privacy-e-cookie-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-e-cookie-policy-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-protocollo-sicurezza-covid-19-tsx": () => import("./../../../src/pages/protocollo-sicurezza-covid19.tsx" /* webpackChunkName: "component---src-pages-protocollo-sicurezza-covid-19-tsx" */),
  "component---src-pages-termini-e-condizioni-operatori-tsx": () => import("./../../../src/pages/termini-e-condizioni-operatori.tsx" /* webpackChunkName: "component---src-pages-termini-e-condizioni-operatori-tsx" */),
  "component---src-pages-termini-e-condizioni-tsx": () => import("./../../../src/pages/termini-e-condizioni.tsx" /* webpackChunkName: "component---src-pages-termini-e-condizioni-tsx" */),
  "component---src-pages-terzo-settore-tsx": () => import("./../../../src/pages/terzo-settore.tsx" /* webpackChunkName: "component---src-pages-terzo-settore-tsx" */),
  "component---src-pages-trattamento-dati-fini-commerciali-tsx": () => import("./../../../src/pages/trattamento-dati-fini-commerciali.tsx" /* webpackChunkName: "component---src-pages-trattamento-dati-fini-commerciali-tsx" */),
  "component---src-pages-welfare-aziendale-tsx": () => import("./../../../src/pages/welfare-aziendale.tsx" /* webpackChunkName: "component---src-pages-welfare-aziendale-tsx" */),
  "component---src-pages-welfare-territorio-tsx": () => import("./../../../src/pages/welfare-territorio.tsx" /* webpackChunkName: "component---src-pages-welfare-territorio-tsx" */)
}

