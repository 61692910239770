import React, { ButtonHTMLAttributes } from 'react';
import { PropsWithChildren } from 'react';
import { CSSProp } from 'styled-components';
import tw, { css, styled, TwStyle } from 'twin.macro';

type colorType = 'primary' | 'dark' | 'success' | 'error';

interface UgoButtonProps extends ButtonHTMLAttributes<unknown> {
  variant: 'outlined' | 'filled';
  color: colorType;
  size?: 'small' | 'medium';
  css?: CSSProp;
  fullWidth?: boolean;
}

const filledButtonStyles: { [key: string]: TwStyle } = {
  primary: tw`
  bg-primary-500 
  border-primary-500 
  hover:bg-dark-500 
  hover:border-dark-500 
  hover:text-white
  active:bg-primary-300 
  active:border-primary-300
  active:text-dark-500
  disabled:bg-primary-400 
  disabled:border-primary-400
  disabled:text-dark-300
  `,
};

const outlinedButtonStyles: { [key: string]: TwStyle } = {
  primary: tw`
  bg-white
  border-primary-500
  hover:border-dark-500 
  active:border-primary-300
  active:text-dark-500
  disabled:border-primary-400
  disabled:text-dark-300
  `,
};

const generalRoundedButtonStyle = css`
  disabled: cursor-default;
  border-width: 2px;
  outline: 0 !important;
  border-style: solid;
  border-radius: 80px;
  ${tw`tracking-h5`};
  ${tw`shadow-default`};
  ${tw`py-3 px-8`};
  ${tw`text-h6`};
  ${tw`font-bold`};
  ${tw`text-dark-500`};
  ${tw`font-sans`};
  ${tw`transition duration-150 ease-out`};
`;

const getFilledButtonStyles = (color: colorType) => {
  return filledButtonStyles[color] || filledButtonStyles['primary'];
};

const getOutlinedButtonStyles = (color: colorType) => {
  return outlinedButtonStyles[color] || outlinedButtonStyles['primary'];
};

const FilledButton = styled.button<UgoButtonProps>(buttonProps => [
  getFilledButtonStyles(buttonProps.color),
  generalRoundedButtonStyle,
  buttonProps.fullWidth && tw`w-full`,
  buttonProps.size === 'small' && tw`py-2 px-5`,
  buttonProps.size === 'small' && tw`text-sm`,
  buttonProps.size === 'medium' && tw`py-3 px-8`,
]);

const OutlinedButton = styled.button<UgoButtonProps>(buttonProps => [
  getOutlinedButtonStyles(buttonProps.color),
  generalRoundedButtonStyle,
  buttonProps.fullWidth && tw`w-full`,
  buttonProps.size === 'small' && tw`py-2 px-3`,
  buttonProps.size === 'small' && tw`text-sm`,
  buttonProps.size === 'medium' && tw`py-3 px-8`,
]);

const Button = (props: PropsWithChildren<UgoButtonProps>) => {
  switch (props.variant) {
    case 'outlined': {
      return <OutlinedButton {...props} />;
    }
    default: {
      return <FilledButton {...props} />;
    }
  }
};

export default Button;
