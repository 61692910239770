import React from 'react';
import { styled } from 'twin.macro';
import { FluidObject } from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
import Rating from '../../core/misc/Rating';

interface AboutPersonProps {
  image?: FluidObject;
  quote: string;
  name: string;
  workRole: string;
}

const AboutPersonAvatar = styled(BackgroundImage)`
  height: 80px;
  width: 80px;
`;

const AboutPerson = (props: AboutPersonProps) => {
  return (
    <div
      tw="bg-white max-w-full p-4 pb-2 rounded-card shadow-md flex flex-col justify-between"
      {...props}
    >
      <div>
        <AboutPersonAvatar
          fluid={props.image}
          tw="rounded-max overflow-hidden mb-4"
        />
        <div tw="text-paragraph-condense mb-4">{props.quote}</div>
      </div>
      <div>
        <p tw="text-paragraph-condense font-bold">{props.name}</p>
        <p tw="text-paragraph-condense font-bold">{props.workRole}</p>
        <div tw="flex justify-center py-2">
          <Rating rating={5} />
        </div>
      </div>
    </div>
  );
};

export default AboutPerson;
