import React, { useState, useEffect, useContext, createContext } from 'react';
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  confirmPasswordReset,
  onAuthStateChanged,
  signInWithCustomToken,
} from 'firebase/auth';
import { app } from '../src/pages';

const authContext = createContext(null);
// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }: any) {
  const auth = useProvideAuth();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}
// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState(null);

  const auth = getAuth(app);

  // Wrap any Firebase methods we want to use making sure ...
  // ... to save the user to state.
  const signin = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password).then(response => {
      setUser(response.user);
      return response.user;
    });
  };

  const signinwithcustomtoken = token => {
    return signInWithCustomToken(auth, token).then(response => {
      setUser(response.user);
      return response.user;
    });
  };

  const signup = (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password).then(
      response => {
        setUser(response.user);
        return response.user;
      }
    );
  };

  const signout = () => {
    return signOut(auth).then(() => {
      setUser(false);
    });
  };
  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      if (user) {
        setUser(user);
      } else {
        setUser(false);
      }
    });
    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);
  // Return the user object and auth methods
  return {
    user,
    signIn: signin,
    signUp: signup,
    signOut: signout,
    sendPasswordResetEmail,
    signInWithCustomToken: signinwithcustomtoken,
    confirmPasswordReset,
  };
}
