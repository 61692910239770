interface NavLink {
  name: string;
  url: string;
  redirectToApp?: boolean;
  children?: NavLink[];
}

const navLinks: NavLink[] = [
  // {
  //   name: 'Calcola preventivo',
  //   redirectToApp: true,
  //   url: '/quote',
  // },
  {
    name: 'Servizi',
    url: '/servizio-accompagnamento-anziani/',
    children: [
      {
        name: 'Accompagnamento per anziani',
        url: '/servizio-accompagnamento-anziani/',
      },
      {
        name: 'Accompagnamento per disabili',
        url: '/servizio-accompagnamento-anziani-e-disabili/',
      },
      {
        name: 'Spesa e commissioni a domicilio per anziani',
        url: '/spesa-e-commissioni-domicilio-anziani/',
      },
      {
        name: 'Compagnia',
        url: '/compagnia-anziani/',
      },
    ],
  },
  {
    name: 'Prezzi',
    url: '/prezzi-accompagnamento/',
    children: [
      {
        name: 'Accompagnamento',
        url: '/prezzi-accompagnamento/',
      },
      {
        name: 'Commissioni',
        url: '/prezzi-commissioni/',
      },
      {
        name: 'Compagnia',
        url: '/prezzi-compagnia/',
      },
    ],
  },
  {
    name: 'Come funziona',
    url: '/il-servizio/',
    children: [
      {
        name: 'Come funziona',
        url: '/il-servizio/',
      },
      {
        name: 'Operatori UGO',
        url: '/accompagnatori/',
      },
      {
        name: 'Protocollo covid',
        url: '/protocollo-sicurezza-covid19/',
      },
    ],
  },
  {
    name: 'Domande frequenti',
    url: '/domande-frequenti/',
  },
  {
    name: 'Aziende',
    url: '/aziende/',
    children: [
      {
        name: 'Aziende',
        url: '/aziende/',
      },
      {
        name: 'Pharma',
        url: '/aziende-farmaceutiche/',
      },
      {
        name: 'Welfare aziendale',
        url: '/welfare-aziendale/',
      },
      {
        name: 'Welfare pubblico',
        url: '/welfare-territorio/',
      },
      {
        name: 'Assicurazioni',
        url: '/assicurazioni/',
      },
      {
        name: 'Terzo settore',
        url: '/terzo-settore/',
      },
    ],
  },
  // {
  //   name: 'Indennità',
  //   url: '/accompagnamento-indennita/',
  //   children: [
  //     {
  //       name: 'Indennità anziani',
  //       url: '/accompagnamento-anziani-indennita/',
  //     },
  //   ],
  // },
];

export { navLinks, NavLink };
