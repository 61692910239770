import { createMuiTheme } from '@material-ui/core/styles';

export const muiTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#F9B233',
      light: '#fdeccc',
    },
  },
});
