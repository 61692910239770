/**
 * Currently, when rendered on the server, all versions of the page get
 * app.hellougo.com as default APP location. When the site is loaded,
 * JS checks for the host name and edits the links to the APP.
 * Therefore, no SEO impact on the default hellougo.com page.
 *
 * In the future, APP LOCATION could be loaded from a netlify variable.
 */

const HOSTNAME = 'hellougo.com';

const getAppRedirectUri = (): string => {
  let isStaging = false;
  const hostname = HOSTNAME;

  if (typeof window !== 'undefined') {
    isStaging =
      location.origin.includes('staging') ||
      location.origin.includes('localhost');
  }

  return `//${isStaging ? 'app-staging' : 'app'}.${hostname}`;
};

export { getAppRedirectUri, HOSTNAME };
