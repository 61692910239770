const FORM_URL =
  'https://af97095e.sibforms.com/serve/MUIEABT36t7rar4J-AuTbIZeaNRBE3PvhwS6En_SrRz3K9gijgnBHc3mvbNuoJQ4kbmeg8J6IzXWcXe6tUT5d-z5qiVUagHLaSdFzuqw-YwH4doFRVo0YkS2M5pW6yuhVI4mwk7DC3NUWp6bVK5NGZf7E6MuYZ1OEBc62Hm3JhCxiuPEyN_ydbsUIEv7kWyCslmEu3Vo1d0VNHDF';

export const saveContactToWelfareLeadGenerationList = (data: {
  email: string;
  phoneNumber?: string;
}) => {
  const formData = new FormData();
  formData.append('EMAIL', data.email);
  // formData.append('SMS', data.phoneNumber);

  const requestOptions: RequestInit = {
    method: 'POST',
    mode: 'no-cors',
    body: formData,
  };

  return fetch(FORM_URL, requestOptions);
};
