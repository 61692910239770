import React, { ReactNode, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { SwiperOptions, Navigation } from 'swiper';
import { TwStyle } from 'twin.macro';
import 'twin.macro';
import uniqueId from 'lodash/uniqueId';
import Slider, { SliderProps } from '../Slider/Slider';

SwiperCore.use([Navigation]);

interface ResponsiveListProps {
  children: ReactNode[];
  twGridItemSetting: TwStyle;
  sliderProps?: SliderProps;
}

const ResponsiveList = ({
  children,
  twGridItemSetting,
  sliderProps,
}: ResponsiveListProps) => {
  const [id, setId] = useState(null);
  const [swiperProps, setSwiperProps] = useState<SwiperOptions>({
    navigation: {
      prevEl: '.nav-prev-' + id,
      nextEl: '.nav-next-' + id,
    },
    slidesPerView: 1,
    breakpoints: {
      0: {
        slidesPerView: 1,
        spaceBetween: 32,
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 32,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 32,
      },
    },
  });

  useEffect(() => {
    const id = uniqueId();
    setId(id);

    setSwiperProps({
      navigation: {
        prevEl: '.nav-prev-' + id,
        nextEl: '.nav-next-' + id,
      },
      slidesPerView: 1,
      breakpoints: {
        0: {
          slidesPerView: 1,
          spaceBetween: 32,
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 32,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 32,
        },
      },
    });
  }, []);

  return (
    <div>
      <div tw="hidden lg:flex flex-wrap justify-center -mx-4 items-center">
        {children.map((p, i) => (
          <div key={i} css={[twGridItemSetting]}>
            {p}
          </div>
        ))}
      </div>
      <div tw="lg:hidden px-5 relative">
        <Slider {...swiperProps} {...sliderProps} tw="pb-10">
          {children.map((c, i) => (
            <SwiperSlide key={i}>{c}</SwiperSlide>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default ResponsiveList;
