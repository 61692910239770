import tw from 'twin.macro';
import styled from 'styled-components';
import React from 'react';
import { PropsWithChildren } from 'react';

const StyledListItem = styled.li`
  position: relative;
  padding-left: 1.25em;
  display: block;
  ${tw`text-paragraph`}
  &::before {
    content: '•';
    left: 0;
    font-size: 2em;
    line-height: 1em;
    position: absolute;
    ${tw`text-primary-500`};
  }
`;

const ListItem = (props: PropsWithChildren<unknown>) => {
  return <StyledListItem {...props}>{props.children}</StyledListItem>;
};

export default ListItem;
