/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// Workaround as swiper styles are not getting included if it's not a named import
// and variable not used: https://github.com/gatsbyjs/gatsby/issues/19446
import styles from 'swiper/swiper-bundle.min.css';
import stylesCustom from './src/theme/style.css';
console.log({ styles, stylesCustom });

import Layout from './src/components/core/layout/Layout/Layout';
import React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { client } from './src/utils/apollo';
import { ProvideAuth, useAuth } from './hooks/use-auth';
import Auth from './src/components/auth/Auth';
import { CookiesProvider } from 'react-cookie';
import AnalyticsProvider from './src/analytics/AnalyticsProvider';
import { useAtom } from 'jotai';
import { isConvertedUserAtom } from './src/state/is-converted-user-atom';

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

const AnalyticsProviderWrapper = ({ children }) => {
  const { user } = useAuth();
  const [isConvertedUser] = useAtom(isConvertedUserAtom);

  return (
    <AnalyticsProvider
      user={user}
      staticParameters={{
        is_converted_user:
          typeof isConvertedUserStore === 'boolean' ? isConvertedUser : 'EMPTY',
      }}
    >
      {children}
    </AnalyticsProvider>
  );
};

export const wrapRootElement = ({ element }) => (
  <ProvideAuth>
    <ApolloProvider client={client}>
      <CookiesProvider>
        <Auth>
          <AnalyticsProviderWrapper>{element}</AnalyticsProviderWrapper>
        </Auth>
      </CookiesProvider>
    </ApolloProvider>
  </ProvideAuth>
);
