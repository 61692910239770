import React from 'react';
import 'twin.macro';
import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import { FluidObject } from 'gatsby-image';

interface CityItemProps {
  image: FluidObject;
  name: string;
}

const StyledImage = styled(BackgroundImage)`
  height: 150px;
`;

const CityItem = (props: CityItemProps) => {
  return (
    <div tw="w-full shadow-card rounded-lg overflow-hidden">
      <StyledImage fluid={props.image} />
      <div tw="px-4 py-3 text-paragraph font-bold">{props.name}</div>
    </div>
  );
};

export default CityItem;
