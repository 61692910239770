import Heading from '../../core/typography/Heading';
import React from 'react';
import CollectEmail from '../CollectEmail/CollectEmail';
import 'twin.macro';

const SubscribeNewsletterLanding = () => {
  return (
    <div tw="w-full text-center">
      <Heading variant="h4" tw="text-center">
        Ricevi il pacchetto informativo!
      </Heading>
      <Heading variant="h6" tw="text-paragraph text-center mb-8 font-normal">
        E iscriviti alla nostra <b>newsletter</b> per ricevere una piccola guida
        dei nostri servizi e restare sempre aggiornato.
      </Heading>
      <CollectEmail offsetTerms />
    </div>
  );
};

export default SubscribeNewsletterLanding;
