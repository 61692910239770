import React from 'react';
import Container from '../../core/layout/Layout/Container';
import 'twin.macro';
import Heading from '../../core/typography/Heading';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { PromoBannerQuery } from '../../../../generated/graphql-types';
import Image from 'gatsby-image';
import Button from '../../core/buttons/Button';
import { TwStyle } from 'twin.macro';
import useDatalayer from '../../../../hooks/use-datalayer';

const PromoBanner2 = ({ background }: { background?: TwStyle }) => {
  const datalayer = useDatalayer();
  const data = useStaticQuery<PromoBannerQuery>(graphql`
    query PromoBanner2 {
      image: file(relativePath: { eq: "UGO_Chiamata.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            presentationHeight
            presentationWidth
            ...GatsbyImageSharpFluidFragment
          }
        }
      }
    }
  `);
  return (
    <div css={[background]}>
      <div
        tw="pt-20 pb-10 overflow-hidden"
        style={{
          backgroundImage: 'linear-gradient(-94deg, #68dec2 0%, #188877 100%)',
        }}
      >
        <Container>
          <div tw="flex relative">
            <div tw="z-10">
              <Heading variant="h3" tw="text-white mb-2">
                Vuoi conoscere il Team UGO?
              </Heading>
              <Heading variant="h5" tw="md:w-2/3 text-white mb-10">
                <p tw="hidden md:block p-0 m-0">
                  Prenota un appuntamento telefonico gratuito con il nostro
                  Customer Service che ogni giorno ascolta e risponde ai bisogni
                  dei nostri utenti.
                </p>
                <p tw="md:hidden p-0 m-0">
                  Prenota un appuntamento telefonico gratuito con il nostro
                  Customer Service.
                </p>
              </Heading>
              <a
                target="_blank"
                tw="font-bold underline"
                href="https://hellougo.typeform.com/info-request-cc?utm_content=greenbar&typeform-source=hellougo.com"
              >
                <Button variant="filled" color="primary">
                  Prenota subito
                </Button>
              </a>
            </div>
            <div tw="w-full md:w-5/6 absolute right-0 -bottom-12">
              <Image tw="w-full" fluid={data.image.childImageSharp.fluid} />
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default PromoBanner2;
