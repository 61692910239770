import React from 'react';
import Bottombar from '../Bottombar/Bottombar';
import 'twin.macro';
import { Container } from '../Layout';
import footerLinks from './footer-links';
import { Link } from 'gatsby';
import Button from '../../buttons/Button';
import { getAppRedirectUri, HOSTNAME } from '../../../utils/getAppRedirectUri';
import useDatalayer from '../../../../../hooks/use-datalayer';
import { useAnalytics } from '../../../../analytics/AnalyticsProvider';

const Footer = () => {
  const [appLocation, setAppLocation] = React.useState<string>(
    '//app.' + HOSTNAME
  );
  const analytics = useAnalytics();

  const datalayer = useDatalayer();

  React.useEffect(() => {
    setAppLocation(getAppRedirectUri());
  }, []);

  return (
    <div tw="bg-dark-500 text-white">
      <div tw="py-20">
        <Container>
          <div tw="flex flex-wrap">
            <div tw="w-full lg:w-1/3">
              <ul>
                {footerLinks.map((link, i) => (
                  <li tw="text-paragraph leading-8" key={link.name + i}>
                    <Link to={link.url}>{link.name}</Link>
                  </li>
                ))}
              </ul>
            </div>
            <div tw="w-full lg:w-1/3 mb-12 lg:mb-0">
              <ul>
                <li tw="pt-5">
                  <div tw="flex space-x-6">
                    <a
                      href="https://www.facebook.com/ugoappsocial"
                      target="_blank"
                      aria-label="facebook"
                      id="btn-fb"
                    >
                      <svg
                        id="regular"
                        enableBackground="new 0 0 24 24"
                        height="22px"
                        viewBox="0 0 24 24"
                        width="22px"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill="#fff"
                          d="m6.812 13.937h2.518v9.312c0 .414.335.75.75.75l4.007.001c.415 0 .75-.337.75-.75v-9.312h2.387c.378 0 .697-.282.744-.657l.498-4c.056-.446-.293-.843-.744-.843h-2.885c.113-2.471-.435-3.202 1.172-3.202 1.088-.13 2.804.421 2.804-.75v-3.577c0-.375-.277-.692-.648-.743-.314-.043-1.555-.166-3.094-.166-7.01 0-5.567 7.772-5.74 8.437h-2.519c-.414 0-.75.336-.75.75v4c0 .414.336.75.75.75zm.75-3.999h2.518c.414 0 .75-.336.75-.75v-3.151c0-2.883 1.545-4.536 4.24-4.536.878 0 1.686.043 2.242.087v2.149c-.402.205-3.976-.884-3.976 2.697v2.755c0 .414.336.75.75.75h2.786l-.312 2.5h-2.474c-.414 0-.75.336-.75.75v9.311h-2.505v-9.312c0-.414-.336-.75-.75-.75h-2.519z"
                        />
                      </svg>
                    </a>
                    <a
                      href="https://www.instagram.com/ugoappsocial/"
                      target="_blank"
                      aria-label="instagram"
                      id="btn-ig"
                    >
                      <svg
                        id="regular"
                        enableBackground="new 0 0 24 24"
                        viewBox="0 0 24 24"
                        height="22px"
                        width="22px"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill="#fff"
                          d="m4.75 24h14.5c2.619 0 4.75-2.131 4.75-4.75v-14.5c0-2.619-2.131-4.75-4.75-4.75h-14.5c-2.619 0-4.75 2.131-4.75 4.75v14.5c0 2.619 2.131 4.75 4.75 4.75zm-3.25-19.25c0-1.792 1.458-3.25 3.25-3.25h14.5c1.792 0 3.25 1.458 3.25 3.25v14.5c0 1.792-1.458 3.25-3.25 3.25h-14.5c-1.792 0-3.25-1.458-3.25-3.25z"
                        />
                        <path
                          fill="#fff"
                          d="m12 18.13c3.38 0 6.13-2.75 6.13-6.13s-2.75-6.13-6.13-6.13-6.13 2.75-6.13 6.13 2.75 6.13 6.13 6.13zm0-10.76c2.553 0 4.63 2.077 4.63 4.63s-2.077 4.63-4.63 4.63-4.63-2.077-4.63-4.63 2.077-4.63 4.63-4.63z"
                        />
                        <path
                          fill="#fff"
                          d="m18.358 7.362c.986 0 1.729-.74 1.729-1.721 0-1.023-.782-1.721-1.728-1.721-.986 0-1.729.74-1.729 1.721 0 1.021.778 1.721 1.728 1.721zm.177-1.886c.316.279-.405.618-.405.166 0-.27.367-.2.405-.166z"
                        />
                      </svg>
                    </a>
                    <a
                      href="https://www.linkedin.com/company/ugo-italy/"
                      target="_blank"
                      aria-label="linkedin"
                      id="ln"
                    >
                      <svg
                        id="regular"
                        enableBackground="new 0 0 24 24"
                        viewBox="0 0 24 24"
                        height="22px"
                        width="22px"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill="#fff"
                          d="m18.59 24c.103 0 4.762-.001 4.66-.001.414 0 .75-.336.75-.75-.35-7.857 1.842-16.148-6.338-16.148-1.573 0-2.826.537-3.729 1.247 0-1.461-1.579-.653-5.224-.87-.414 0-.75.336-.75.75.302 14.166-.674 15.771.75 15.771h4.66c1.353 0 .492-1.908.75-8.188 0-2.594.75-3.102 2.046-3.102 1.434 0 1.675.996 1.675 3.228.257 6.167-.598 8.063.75 8.063zm-2.425-12.791c-4.491 0-3.546 4.938-3.546 11.29h-3.16v-13.521h2.974v1.298c0 .72 1.097 1.074 1.479.35.492-.934 1.77-2.025 3.75-2.025 3.527 0 4.838 1.733 4.838 6.396v7.503h-3.16c0-7.144.756-11.291-3.175-11.291z"
                        />
                        <path
                          fill="#fff"
                          d="m1.122 7.479c-1.42 0-.448 1.585-.75 15.771 0 .414.336.75.75.75h4.665c1.42 0 .448-1.585.75-15.771 0-1.295-1.881-.531-5.415-.75zm3.915 15.021h-3.165v-13.521h3.165z"
                        />
                        <path
                          fill="#fff"
                          d="m3.452 0c-4.576 0-4.548 6.929 0 6.929 4.545 0 4.581-6.929 0-6.929zm0 5.429c-2.568 0-2.592-3.929 0-3.929 2.597 0 2.564 3.929 0 3.929z"
                        />
                      </svg>
                    </a>
                    <a
                      href="https://twitter.com/ugoappsocial"
                      target="_blank"
                      aria-label="twitter"
                      id="btn-tw"
                    >
                      <svg
                        id="Capa_1"
                        xmlns="http://www.w3.org/2000/svg"
                        height="22px"
                        width="22px"
                        viewBox="26.8 48 460.2 416"
                      >
                        <path
                          fill="#fff"
                          d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"
                        />
                      </svg>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
            <div tw="w-full lg:w-1/3 flex lg:justify-end">
              <div tw="w-full lg:w-4/5 space-y-3">
                <div>
                  <Link
                    to="/#hero"
                    onClick={() => {
                      datalayer.triggerReservationIntent({ action: 'footer' });

                      datalayer.qf4Events.reservationIntent('Footer');

                      analytics.submitEvent<'r-intent-p'>('r-intent-p', {
                        location: 'm-qf-footer_button',
                      });
                    }}
                  >
                    <Button variant="filled" color="primary" fullWidth>
                      Prenota il tuo servizio
                    </Button>
                  </Link>
                </div>
                <div>
                  <Link
                    to="/lavora-con-ugo/"
                    onClick={() => {
                      analytics.submitEvent<'ja-intent-d'>('ja-intent-d', {
                        location: 'm-jaf-footer_button',
                      });
                    }}
                  >
                    <Button variant="outlined" color="primary" fullWidth>
                      Diventa caregiver UGO
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <Bottombar />
    </div>
  );
};

export default Footer;
