import BackgroundImage from 'gatsby-background-image';
import React, { ReactNode } from 'react';
import Heading from '../../core/typography/Heading';
import Button from '../../core/buttons/Button';
import 'twin.macro';
import { FluidObject } from 'gatsby-image';
import List from '../../core/lists/List';
import ListItem from '../../core/lists/ListItem';
import { getAppRedirectUri, HOSTNAME } from '../../utils/getAppRedirectUri';

interface ServiceItemProps {
  title: string;
  image: FluidObject;
  description: string | ReactNode;
  features: string[];
  url: string;
}

const ServiceItem = (props: ServiceItemProps) => {
  const [appLocation, setAppLocation] = React.useState<string>(
    '//app.' + HOSTNAME
  );

  React.useEffect(() => {
    setAppLocation(getAppRedirectUri());
  }, []);

  return (
    <div tw="flex flex-col flex-1">
      <div tw="mb-auto">
        <BackgroundImage
          fluid={props.image}
          style={{ height: '220px', width: '100%' }}
          tw="rounded-2xl overflow-hidden mb-5"
        />
        <Heading variant="h5">{props.title}</Heading>
        <p tw="text-paragraph-condense mb-5">{props.description}</p>
        <List tw="list-inside list-none mb-5">
          {props.features.map(f => (
            <ListItem tw="font-bold" key={f}>
              {f}
            </ListItem>
          ))}
        </List>
      </div>
      <div>
        <a href={props.url}>
          <Button variant="filled" color="primary">
            Scopri di più
          </Button>
        </a>
      </div>
    </div>
  );
};

export default ServiceItem;
