import React, { PropsWithChildren } from 'react';
import tw, { css } from 'twin.macro';

// Extend later if different colors needed

const List = (props: PropsWithChildren<unknown>) => {
  return (
    <ul style={{ position: 'relative' }} {...props}>
      {props.children}
    </ul>
  );
};

export default List;
