import range from 'lodash.range';
export const SUPPORTED_CITIES_ZIP_CODES = [
  {
    name: 'ancona',
    lng: 13.5168133,
    lat: 43.6166489,
    zipCodes: [60100, ...range(60010, 60131)],
  },
  {
    name: 'avellino',
    lng: 14.7911166,
    lat: 40.9144676,
    zipCodes: [83100, ...range(83010, 83059)],
  },
  {
    name: 'bergamo',
    lng: 9.668711,
    lat: 45.6941984,
    zipCodes: [24100, ...range(24010, 24129)],
  },
  {
    name: 'bologna',
    lng: 11.3426,
    lat: 44.4932318,
    zipCodes: [40100, ...range(40010, 40141)],
  },
  {
    name: 'brescia',
    lng: 10.2156823,
    lat: 45.5403137,
    zipCodes: [25100, ...range(25010, 25136)],
  },
  {
    name: 'firenze',
    lng: 11.2463453,
    lat: 43.7751269,
    zipCodes: [50100, ...range(50010, 50145)],
  },
  {
    name: 'foggia',
    lng: 15.5406353,
    lat: 41.462094,
    zipCodes: [71100, ...range(71010, 71122)],
  },
  {
    name: 'genova',
    lng: 8.9097227,
    lat: 44.4083582,
    zipCodes: [16100, ...range(16010, 16167)],
  },
  {
    name: 'lecce',
    lng: 18.1666213,
    lat: 40.3514381,
    zipCodes: [73100, ...range(73010, 73100)],
  },
  {
    name: 'milano',
    lng: 9.18854,
    lat: 45.464664,
    zipCodes: [20100, ...range(20001, 20162)],
  },
  {
    name: 'monza',
    lng: 9.2735078,
    lat: 45.583547,
    zipCodes: [20900, ...range(20811, 20900)],
  },
  {
    name: 'padova',
    lng: 11.8713873,
    lat: 45.4089257,
    zipCodes: [35100, ...range(35010, 35143)],
  },
  {
    name: 'roma',
    lng: 12.4841008,
    lat: 41.8962146,
    zipCodes: ['00100', ...range(118, 199).map(zip => `00${zip}`)],
  },
  {
    name: 'siena',
    lng: 11.3271333,
    lat: 43.3191518,
    zipCodes: [53100, ...range(53011, 53100)],
  },
  {
    name: 'torino',
    lng: 7.6778283,
    lat: 45.0712738,
    zipCodes: [10100, ...range(10010, 10156)],
  },
  {
    name: 'varese',
    lng: 8.8209043,
    lat: 45.8180868,
    zipCodes: [21100, ...range(21010, 21100)],
  },
  {
    name: 'palermo',
    lng: 13.3548028,
    lat: 38.1142286,
    zipCodes: [90100, ...range(90010, 90051)],
  },
  {
    name: 'catania',
    lng: 15.0850115,
    lat: 37.5023308,
    zipCodes: [95100, ...range(95010, 95131)],
  },
  {
    name: 'siracusa',
    lng: 15.2755113,
    lat: 37.0921263,
    zipCodes: [96100, ...range(96010, 96019)],
  },
  {
    name: 'rimini',
    lng: 12.563527,
    lat: 44.061755,
    zipCodes: [47900, ...range(47814, 47924)],
  },
  {
    name: 'agrigento',
    lng: 13.585126,
    lat: 37.30921,
    zipCodes: [92100, ...range(92010, 92031)],
  },
  {
    name: 'messina',
    lng: 15.5542,
    lat: 38.1937,
    zipCodes: [98100, ...range(98020, 98168)],
  },
  {
    name: 'olbia',
    lng: 9.5,
    lat: 40.916668,
    zipCodes: ['07026', ...range(7020, 7038).map(zip => `0${zip}`)],
  },
];

/**
 * @description A list of supported cities (they can be treated as city ids)
 * where we provide services.
 *  - They are lowercase due to easier/saner fuse.js search implementation
 *  - Use function `getSupportedCitites` if you want to use the city list
 *  in a form. The function will append a production test city for admins.
 */
export const SUPPORTED_CITIES = [
  { name: 'bergamo', lng: 9.668711, lat: 45.6941984 },
  { name: 'bologna', lng: 11.3426, lat: 44.4932318 },
  { name: 'brescia', lng: 10.2156823, lat: 45.5403137 },
  { name: 'firenze', lng: 11.2463453, lat: 43.7751269 },
  { name: 'genova', lng: 8.9097227, lat: 44.4083582 },
  { name: 'milano', lng: 9.18854, lat: 45.464664 },
  { name: 'monza', lng: 9.2735078, lat: 45.583547 },
  { name: 'padova', lng: 11.8713873, lat: 45.4089257 },
  { name: 'roma', lng: 12.4841008, lat: 41.8962146 },
  { name: 'torino', lng: 7.6778283, lat: 45.0712738 },
  { name: 'catania', lng: 15.0850115, lat: 37.5023308 },
  // { name: 'PRODUCTION_TEST_CITY', lng: 9.18854, lat: 45.464664 },
];

/**
 * @description returns a PRODUCTION_TEST_CITY if the user is an admin
 */
export const getSupportedCities = (isAdmin: boolean) => {
  const cities = SUPPORTED_CITIES_ZIP_CODES.map(c => c.name);

  if (!isAdmin) {
    cities.splice(cities.indexOf('PRODUCTION_TEST_CITY'), 1);
  }

  return cities;
};
