const FORM_URL =
  'https://af97095e.sibforms.com/serve/MUIEAL4xTtcO85Sptj6gO7x5ajC39h3LXr93UMr6Zp1JKprsPXLXJnveznnKiAeLgU6ijDJddmM8fg_N5nIAS727H0g5lC3ktH7B13fMj_MD-cs5RqSg0IcDUpagnRhdZnXayzZq7AhNIvi8tNkRDnOQz6Sb5QZEyl-wpP9i6zdpR09gZCcVeMDs6CaS1Aui90YRJr0IEq7JNbtc';

export const collectPromotionEmail = (data: {
  email: string;
  ab_variant_id?: any;
}) => {
  const formData = new FormData();
  formData.append('EMAIL', data.email);
  formData.append('AB_VARIANT_ID', data.ab_variant_id);

  const requestOptions: RequestInit = {
    method: 'POST',
    mode: 'no-cors',
    body: formData,
  };

  return fetch(FORM_URL, requestOptions);
};
