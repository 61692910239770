import React from 'react';
import ServiceItem from '../../views/ServiceItem/ServiceItem';
import 'twin.macro';
import { graphql, useStaticQuery } from 'gatsby';
import { OurServicesQuery } from 'generated/graphql-types';
import Heading from '../../core/typography/Heading';
import { serviceItems } from './services-data';

const OurServices = () => {
  const services = useStaticQuery<OurServicesQuery>(graphql`
    query OurServices {
      accompagnamento: file(
        relativePath: { eq: "accompagnamento_service.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluidFragment
          }
        }
      }
      commissioni: file(relativePath: { eq: "commissioni_service.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluidFragment
          }
        }
      }
      compagnia: file(relativePath: { eq: "compagnia_service.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluidFragment
          }
        }
      }
    }
  `);

  return (
    <div>
      <Heading tw="mb-10" variant="h3">
        I nostri servizi
      </Heading>
      <div tw="flex flex-wrap md:flex-nowrap md:space-x-20 space-y-20 md:space-y-0">
        {serviceItems.map(service => (
          <div tw="w-full md:w-1/3 flex" key={service.title}>
            <ServiceItem
              {...service}
              image={services[service.image].childImageSharp.fluid}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurServices;
