import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { ReviewSourceLogosQuery } from '../../../../generated/graphql-types';
import Image from 'gatsby-image';

export const ReviewSourceLogo = () => {
  const reviewSourceLogos = useStaticQuery<ReviewSourceLogosQuery>(graphql`
    query ReviewSourceLogos {
      google: file(relativePath: { eq: "google.png" }) {
        childImageSharp {
          fluid(maxWidth: 40) {
            ...GatsbyImageSharpFluidFragment
          }
        }
      }
    }
  `);

  return (
    <Image
      alt="Google"
      fluid={reviewSourceLogos.google.childImageSharp.fluid}
    />
  );
};
