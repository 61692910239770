interface FooterLink {
  name: string;
  url: string;
}

const footerLinks: FooterLink[] = [
  {
    name: 'Blog',
    url: '/blog/',
  },
  // {
  //   name: 'Regolamento Utenti',
  //   url: '/regolamento-utenti',
  // },
  {
    name: 'Prezzi',
    url: '/prezzi-accompagnamento/',
  },
  {
    name: 'Indennità',
    url: '/accompagnamento-indennita/',
  },
  {
    name: 'I nostri partner',
    url: '/i-partner-di-ugo/',
  },
  {
    name: 'Careers',
    url:
      'https://hellougo.notion.site/Careers-UGO-374e29009b63496c8668deb877b721ca',
  },
  {
    name: 'Domande frequenti',
    url: '/domande-frequenti/',
  },
  {
    name: 'Termini e condizioni',
    url: '/termini-e-condizioni/',
  },
  {
    name: 'Privacy e Cookie Policy',
    url: '/privacy-e-cookie-policy/',
  },
];

export default footerLinks;
