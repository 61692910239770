import tw from 'twin.macro';
import React, { PropsWithChildren } from 'react';

/**
 * Wrapper component for the breakpointed views
 */
const Container = (props: PropsWithChildren<unknown>) => {
  return (
    <div css={[tw`max-w-7xl! container mx-auto px-5`]} {...props}>
      {props.children}
    </div>
  );
};

export default Container;
