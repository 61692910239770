import React from 'react';
import { ReactNode } from 'react';

interface Operator {
  title: string | ReactNode;
  summary: string | ReactNode;
  key: string;
}

export const operators: Operator[] = [
  {
    key: 'francesca',
    title: (
      <>
        <b>Francesca,</b> UGO di Milano con noi da marzo 2020
      </>
    ),
    summary: (
      <>
        "Cerco di <b>essere presente</b>, rispettando il silenzio dell’utente o
        il suo bisogno di interazione, di essere disponibile senza essere
        invadente, senza sostituirmi ma standogli accanto. Un malato non è la
        malattia, è una persona e sente il bisogno di manifestare e
        riappropriarsi del suo ruolo nella quotidianità, io sono lì per
        esserci."
      </>
    ),
  },
  {
    key: 'fernando',
    title: (
      <>
        <b>Fernando,</b> UGO di Roma dal 2020
      </>
    ),
    summary: (
      <>
        "Ogni giorno prendo coscienza di come la malattia non sia solo fisica,
        non abbia bisogno solo di medicine.{' '}
        <b>
          Sono convinto che si debba occuparsi della persona, di come vive ciò
          che gli capita.
        </b>{' '}
        Io cerco di fare un pezzettino di questo percorso. Lavorare con persone
        fragili cambia il modo di rapportarsi con tutti gli altri"
      </>
    ),
  },
  {
    key: 'simona',
    title: (
      <>
        <b>Simona,</b> UGO di Milano dal 2018
      </>
    ),
    summary: (
      <>
        "Ho la fortuna di essere una persona con una forte tranquillità
        interiore e le persone a me vicine la avvertono, per cui hanno molta
        fiducia in me e nelle mie capacità di gestione. Svolgo già attività di
        volontariato e{' '}
        <b>
          far parte di UGO non è solo svolgere un lavoro ma dare benessere a
          persone che soffrono o sono sole
        </b>
        "
      </>
    ),
  },
  {
    key: 'sonia',
    title: (
      <>
        <b>Sonia,</b> UGO di Milano dal 2020
      </>
    ),
    summary: (
      <>
        "Credo di essere brava nel rapportarmi alle persone.{' '}
        <b>Dare conforto e spensieratezza</b> mi appaga. Una volta una signora
        mi ha  ringraziata perchè durante le 4 ore trascorse insieme era
        riuscita finalmente a non pensare alla malattia del marito, un pensiero
        ricorrente delle sue giornate"
      </>
    ),
  },
  {
    key: 'giacomo',
    title: (
      <>
        <b>Giacomo,</b> UGO di Monza dal 2016
      </>
    ),
    summary: (
      <>
        Musicista e operatore UGO storico. “<b>Sensibilità</b> e{' '}
        <b>gentilezza</b>, sono fondamentali per accompagnare i tanti utenti nel
        corso del tempo, da signore in cerca di aiuto per la spesa a persone
        demoralizzate da un difficile percorso di terapia”.
      </>
    ),
  },
  {
    key: 'mattia',
    title: (
      <>
        <b>Mattia,</b> UGO di Firenze da novembre 2020
      </>
    ),
    summary: (
      <>
        "In fotografia esiste il banco ottico, una macchina di grande formato
        che richiede esperienza e un ordine preciso.{' '}
        <b>Ogni imprevisto deve essere calcolato e preso in considerazione.</b>{' '}
        Quando accompagno un utente sento di svolgere un compito simile:{' '}
        <b>tutto deve filare liscio</b> fino al momento dello scatto."
      </>
    ),
  },
  {
    key: 'gianni',
    title: (
      <>
        <b>Gianni,</b> UGO di Firenze dal 2020{' '}
      </>
    ),
    summary: (
      <>
        “Un giorno saremo noi a trovarci dall’altra parte, anche solo per una
        questione anagrafica.{' '}
        <b>
          In ogni servizio cerco sempre di trasmettere fiducia ed entusiasmo
        </b>{' '}
        e fare tesoro delle esperienze che vogliono condividere con me, delle
        loro sensazioni per quando, un domani, ci troveremo noi nel ruolo di
        dover essere assistiti."
      </>
    ),
  },
  {
    key: 'massimiliano',
    title: (
      <>
        <b>Massimiliano,</b> UGO di Milano dal 2019
      </>
    ),
    summary: (
      <>
        Incarna alla perfezione sicurezza, affidabilità e concretezza. “
        <b>Cerco di comprendere i piccoli e grandi bisogni delle persone</b> che
        affianco, arrivando dappertutto con allegria e senso pratico. Per me
        l'obiettivo è garantire a chi accompagno di stare bene, al parco come in
        ospedale”
      </>
    ),
  },
  {
    key: 'gabriele',
    title: (
      <>
        <b>Gabriele,</b> UGO di Catania dal 2021{' '}
      </>
    ),
    summary: (
      <>
        Giovane e molto simpatico, <b>capace di strappare</b> un sorriso anche
        al più burbero degli utenti. Ha iniziato a collaborare con UGO come
        alternativa professionale al suo lavoro e si è da subito integrato,
        comprendendo che a volte basta essere se stessi per donare benessere
        alle persone.
      </>
    ),
  },
];
