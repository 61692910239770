import React from 'react';
import { InputHTMLAttributes } from 'react';
import tw from 'twin.macro';

interface RoundedTextFieldProps extends InputHTMLAttributes<unknown> {
  fullWidth?: boolean;
  iconLeft?: JSX.Element;
  iconRight?: JSX.Element;
  error?: string;
  label?: string;
}

const RoundedTextField = ({
  error,
  iconLeft,
  iconRight,
  ...rest
}: RoundedTextFieldProps) => {
  return (
    <>
      {rest.label && (
        <label tw="font-bold mb-1 inline-block px-4">{rest.label}</label>
      )}
      <div
        css={[
          tw`relative h-14 w-full rounded-full overflow-hidden py-2 px-2 bg-white flex items-center shadow-lg focus-within:ring focus-within:ring-primary-500 text-left flex items-center`,
          error && tw`ring ring-red-300`,
        ]}
      >
        {iconLeft ? iconLeft : null}
        <input
          css={[tw`w-full focus:outline-none! pl-2 pr-10`]}
          defaultValue=""
          {...rest}
        />
        <div tw="absolute right-2">{iconRight ? iconRight : null}</div>
      </div>
      {error ? (
        <p tw="pt-1 text-error-500 px-5 font-bold">{error}</p>
      ) : (
        <p tw="pt-1">&nbsp;</p>
      )}
    </>
  );
};

export default RoundedTextField;
