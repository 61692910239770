import React, { useEffect, useRef, useState } from 'react';
import BackgroundImage from 'gatsby-background-image';
import styled from 'styled-components';
import Heading from '../../core/typography/Heading';
import { Container } from '../../core/layout/Layout';
import Image, { FluidObject } from 'gatsby-image';
import Button from '../../core/buttons/Button';
import { getAppRedirectUri } from '../../utils/getAppRedirectUri';
import useDatalayer from '../../../../hooks/use-datalayer';
import RoundedSelect from '../../core/form-controls/RoundedSelect/RoundedSelect';
import { Listbox, RadioGroup } from '@headlessui/react';
import heartIcon from '../../../assets/images/icons/plus-heart.png';
import homePin from '../../../assets/images/icons/home-pin.png';
import search from '../../../assets/images/icons/Lente.png';
import tw from 'twin.macro';
import { SUPPORTED_CITIES } from '../../../data/supported-cities';
import { Dialog, DialogContent } from '@material-ui/core';
import { useAtom } from 'jotai';
import { navigatedToQuoteStep1Atom } from '../../../pages';
import { addDays, format } from 'date-fns';
import { useAnalytics } from '../../../analytics/AnalyticsProvider';
import { useInView } from 'react-intersection-observer';
import {
  isCalculateButtonVisibleAtom,
  isMobileCalculateButtonVisibleAtom,
} from '../../core/layout/Layout/Layout';

const Hero = styled(BackgroundImage)`
  min-height: 75vh;
  &::before,
  &::after {
    transform: scaleX(-1);
  }
`;

interface MainHeaderProps {
  image: FluidObject;
}

const services = [
  {
    value: 'CAREGIVING_WITH_UGOS_CAR',
    short: "Accompagnamento con l'auto",
    description: (
      <>
        <b>Accompagnamento con l’auto</b> e affiancamento per visite, terapie,
        commissioni e appuntamenti
      </>
    ),
    longDescription:
      'Il Caregiver UGO ti raggiunge e ti accompagna in auto e ti supporta in tutto: orientamento, sostegno fisico, burocrazia, compagnia. Al termine ti riaccompagna a casa. Le auto sono normali, senza pedana per la carrozzina.',
  },
  {
    value: 'CAREGIVING_WITH_USERS_CAR',
    short: 'UGO guida la tua auto',
    description: (
      <>
        <b>UGO guida la tua auto</b> per te e ti accompagna dove vuoi
      </>
    ),
    longDescription:
      'Il Caregiver UGO guida la tua auta e ti accompagna dove vuoi.',
  },
  {
    value: 'CAREGIVING_WITHOUT_CAR',
    short: 'Accompagnamento fuori, senza auto',
    description: (
      <>
        Accompagnamento fuori, <b>senza auto</b>
      </>
    ),
    longDescription:
      'Il Caregiver UGO ti accompagna a fare una passeggiata fuori casa, a piedi oppure sui mezzi pubblici e resta al tuo fianco per tutto il tempo necessario.',
  },
  {
    value: 'CAREGIVING_VIA_TELEMEDICINE',
    short: 'Compagnia in presenza a casa',
    description: (
      <>
        <b>Compagnia</b> in presenza a casa per qualche ora
      </>
    ),
    longDescription:
      'Il Caregiver UGO trascorre del tempo a casa con te per fare due chiacchiere, giocare a carte, leggere, dare una mano con piccole mansioni in casa. Ti ricordiamo che UGO non è un servizio di badanti, NON si occupa di igiene nè delle pulizie nè della preparazione pasti.',
  },
  {
    value: 'ERRANDS',
    short: 'Commissioni varie al posto tuo',
    description: (
      <>
        <b>Commissioni varie al posto tuo.</b> Ci pensa UGO!
      </>
    ),
    longDescription:
      'Il Caregiver UGO si occupa di fare la spesa, ritirare un referto medico, andare in farmacia per te oppure in Posta o in banca. E tanto altro - basta chiedere!',
  },
  {
    value: 'PHONE_ASSISTANCE',
    short: 'Compagnia telefonica',
    description: (
      <>
        <b>Compagnia telefonica</b>
      </>
    ),
    longDescription:
      'Il Caregiver UGO ti contatta per fare due chiacchiere e prestarti ascolto in qualsiasi occasione.',
  },
];

const MainHeader = (props: MainHeaderProps) => {
  const [selectedService, setSelectedService] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showServiceError, setShowServiceError] = useState(false);
  const [showCityError, setShowCityError] = useState(false);
  const [showCityTip, setShowCityTip] = useState(false);
  const [isCalculateButtonVisible, setIsCalculateButtonVisible] = useAtom(
    isCalculateButtonVisibleAtom
  );
  const [
    isMobileCalculateButtonVisible,
    setIsMobileCalculateButtonVisible,
  ] = useAtom(isMobileCalculateButtonVisibleAtom);

  const analytics = useAnalytics();

  const datalayer = useDatalayer();

  const startReservation = (isForTomorrow = false) => {
    if (!selectedService && !isForTomorrow) {
      setShowServiceError(true);
    }

    if (!selectedCity && !isForTomorrow) {
      setShowCityError(true);
      setShowCityTip(false);
    }

    if ((!selectedCity || !selectedService) && !isForTomorrow) {
      return;
    }

    const appUrl = getAppRedirectUri() + '/quote/quote-details';

    const quoteData = {};
    if (selectedCity) {
      quoteData['service_city'] = selectedCity;
    }
    if (selectedService) {
      quoteData['service'] = selectedService.value;
    }

    if (isForTomorrow) {
      quoteData['date'] = format(addDays(new Date(), 1), 'yyyy-MM-dd');
    }
    const base64Quote = btoa(JSON.stringify(quoteData));

    if (typeof window !== 'undefined') {
      datalayer.v3.triggerFunnelStarted(window.location.pathname);
    }

    if (isForTomorrow) {
      datalayer.qf4Events.reservationStartedForTomorrow();
      analytics.submitEvent<'r-started-p'>('r-started-p', {
        location: 'm-qf-hero_tomorrow_button',
      });
    } else {
      datalayer.qf4Events.reservationStartedMaster();
      analytics.submitEvent<'r-started-p'>('r-started-p', {
        location: 'm-qf-hero_calculate_button',
      });
    }

    location.href = `${appUrl}?bquote=${base64Quote}&back=landing`;
  };

  const proceedWithoutCity = () => {
    const appUrl = getAppRedirectUri() + '/quote/quote-details';
    const base64Quote = btoa(
      JSON.stringify({
        service: selectedService.value,
      })
    );
    location.href = `${appUrl}?bquote=${base64Quote}&back=landing`;
    datalayer.triggerReservationStarted('preventivo');
    analytics.submitEvent<'r-started-p'>('r-started-p', {
      location: 'm-qf-hero_calculate_button',
    });
  };

  const [navigatedToQuoteStep1] = useAtom(navigatedToQuoteStep1Atom);
  const [pulse, setPulse] = useState(false);

  useEffect(() => {
    setPulse(navigatedToQuoteStep1);
    if (navigatedToQuoteStep1) {
      setShowCityError(false);
      setShowCityTip(true);
      setShowServiceError(false);
    }
  }, [navigatedToQuoteStep1]);

  const { ref: largeScreenRef, inView: largeScreenButtonInView } = useInView({
    /* Optional options */
    threshold: 1,
    initialInView: true,
  });

  useEffect(() => {
    setIsCalculateButtonVisible(largeScreenButtonInView);
  }, [largeScreenButtonInView]);

  const { ref: smallScreenRef, inView: smallScreenButtonInView } = useInView({
    /* Optional options */
    threshold: 1,
    initialInView: true,
  });

  useEffect(() => {
    setIsMobileCalculateButtonVisible(smallScreenButtonInView);
  }, [smallScreenButtonInView]);

  return (
    <div id="hero">
      <Hero
        tw="hidden md:flex items-center max-w-full"
        Tag="section"
        fluid={props.image}
      >
        <div
          tw="w-1/2 absolute left-0 top-0 h-full opacity-90"
          style={{ backgroundColor: '#FFAB27', mixBlendMode: 'multiply' }}
        ></div>
        <Container>
          <div tw="w-full md:w-full py-20 z-10 relative">
            <div tw="md:w-1/2">
              <p tw="text-3xl font-semibold mb-4">
                UGO ti accompagna e ti affianca
              </p>
              <Heading variant="h1" tw="mb-10 font-black leading-none text-5xl">
                Trova il supporto giusto per te e per i tuoi cari
              </Heading>
            </div>
            <div>
              <div className={pulse && 'heartbeat'}>
                <div
                  css={[
                    tw`w-full flex flex-wrap transform transition-transform duration-300 rounded-xl`,
                  ]}
                >
                  <div tw="flex items-center w-3/4 p-6 bg-white bg-opacity-80 rounded-l-xl">
                    <div tw="mb-2 md:mb-0 w-full md:w-2/5 md:pr-2 relative">
                      <div tw="z-50 relative">
                        <RoundedSelect
                          label="Clicca e scegli il servizio che vuoi"
                          error={showServiceError ? 'Seleziona servizio' : null}
                          value={selectedService}
                          onSelect={v => {
                            setShowServiceError(false);
                            setSelectedService(v);
                            datalayer.triggerReservationServiceChoice(v.value);
                            datalayer.qf4Events.serviceSelected();
                            analytics.submitEvent<'r-service_selected-p'>(
                              'r-service_selected-p',
                              {
                                location: 'm-qf-hero_dropdown',
                              }
                            );
                          }}
                          valueLabelAccessor="short"
                          placeholder="Di quale servizio hai bisogno?"
                          iconLeft={
                            <img src={heartIcon} height="24px" width="24px" />
                          }
                          iconRight={
                            <div tw="h-9 w-9 rounded-full bg-primary-500 flex items-center justify-center ml-auto pl-1 flex-shrink-0">
                              <img
                                src={search}
                                height="14px"
                                width="14px"
                                tw="mr-1"
                              />
                            </div>
                          }
                          wrapperStyle={tw`z-50`}
                        >
                          {services.map(service => (
                            <Listbox.Option
                              key={service.value}
                              value={service}
                              tw="p-2 hover:bg-gray-50 cursor-pointer border-b border-gray-100"
                            >
                              {service.description}
                            </Listbox.Option>
                          ))}
                        </RoundedSelect>
                        {(selectedService ||
                          (!selectedService && navigatedToQuoteStep1)) &&
                          !showServiceError && (
                            <p tw="text-sm font-bold text-gray-600 w-full px-4 mt-2 pt-2 bg-primary-200 rounded-b-lg pb-2 transform -translate-y-8 pt-8 z-10 absolute top-full transition-all shadow-lg">
                              {!selectedService && navigatedToQuoteStep1 && (
                                <p tw="text-center text-error-400">
                                  Si prega di selezionare il servizio
                                </p>
                              )}
                              {selectedService &&
                                selectedService?.longDescription}
                            </p>
                          )}
                      </div>
                    </div>
                    <div tw="w-full md:w-2/5 md:px-2 relative">
                      <div tw="relative">
                        <RoundedSelect
                          label="Scegli la tua città o quella più vicina"
                          error={showCityError ? 'Seleziona città' : null}
                          value={
                            typeof selectedCity === 'string'
                              ? selectedCity.charAt(0).toUpperCase() +
                                selectedCity.slice(1)
                              : selectedCity
                          }
                          onSelect={v => {
                            setShowCityError(false);

                            analytics.submitEvent<'r-city_selected-p'>(
                              'r-city_selected-p',
                              {
                                location: 'm-qf-hero_dropdown',
                              }
                            );

                            setSelectedCity(v);
                          }}
                          placeholder="Seleziona la città più vicina"
                          iconLeft={
                            <img src={homePin} height="24px" width="24px" />
                          }
                          iconRight={
                            <div tw="h-9 w-9 rounded-full bg-primary-500 flex items-center justify-center ml-auto pl-1 flex-shrink-0">
                              <img
                                src={search}
                                height="14px"
                                width="14px"
                                tw="mr-1"
                              />
                            </div>
                          }
                        >
                          {SUPPORTED_CITIES.map(city => (
                            <Listbox.Option
                              key={city.name}
                              value={city.name}
                              tw="p-2 hover:bg-gray-50 cursor-pointer border-b border-gray-100"
                            >
                              {city.name.charAt(0).toUpperCase() +
                                city.name.slice(1)}
                            </Listbox.Option>
                          ))}
                        </RoundedSelect>
                      </div>
                    </div>
                    <div tw="w-full md:w-1/5 md:pl-2">
                      <div ref={largeScreenRef}>
                        <p tw="font-bold text-center mb-1 relative">&nbsp;</p>
                        <Button
                          onClick={() => startReservation(false)}
                          variant="filled"
                          color="primary"
                        >
                          Calcola
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div tw="w-1/4 flex items-center justify-center relative">
                    <div
                      tw="opacity-80 absolute top-0 left-0 w-full h-full rounded-r-xl overflow-hidden"
                      style={{
                        backgroundColor: '#F8B142',
                        mixBlendMode: 'multiply',
                      }}
                    ></div>
                    <div tw="z-10">
                      <p tw="font-bold text-center mb-1 relative">
                        Hai bisogno subito di UGO?
                      </p>
                      <div tw="flex justify-center">
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => startReservation(true)}
                        >
                          Prenota per domani
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Dialog
              disableAutoFocus
              disableEnforceFocus
              open={showErrorModal}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <p tw="mb-5">
                  Attenzione! Nella specifica area geografica selezionata non ci
                  sono operatori attivi. Puoi comunque procedere con il
                  preventivo e con la prenotazione, selezionando la città più
                  vicina.
                  <br />
                  <br />
                  Ricorda che il prezzo del servizio terrà conto anche della
                  trasferta dell'operatore e che non possiamo garantirti che la
                  prenotazione sarà accettata da un caregiver UGO.
                  <br />
                  <br />
                  Stiamo lavorando per arrivare anche nella tua città!
                </p>
                <div tw="flex justify-end pb-3 items-center space-x-2">
                  <Button
                    color="primary"
                    variant="outlined"
                    size="small"
                    onClick={() => setShowErrorModal(false)}
                  >
                    Chiudi
                  </Button>
                  <Button
                    variant="filled"
                    color="primary"
                    size="small"
                    onClick={() => {
                      setShowErrorModal(false);
                      proceedWithoutCity();
                    }}
                  >
                    Ho capito, continua.
                  </Button>
                </div>
              </DialogContent>
            </Dialog>
          </div>
        </Container>
      </Hero>
      <div tw="md:hidden">
        <Image fluid={props.image} />
        <div tw="pt-8">
          <Container tw="pb-10">
            <p tw="text-primary-500 text-xl font-bold mb-2">
              UGO ti aiuta e ti accompagna
            </p>
            <Heading variant="h1" tw="mb-3 font-black leading-none text-3xl">
              Trova il supporto giusto per te e per i tuoi cari
            </Heading>
          </Container>
          <div tw="bg-primary-200">
            <Container>
              <div tw="w-full transform -translate-y-7">
                <div tw="w-full" className={pulse && 'heartbeat'}>
                  <div tw="mb-2 xl:mb-0 w-full md:w-1/2 md:pr-2">
                    <RoundedSelect
                      error={showServiceError ? 'Seleziona servizio' : null}
                      value={selectedService}
                      onSelect={v => {
                        setShowServiceError(false);
                        setSelectedService(v);
                      }}
                      valueLabelAccessor="short"
                      placeholder="Di quale servizio hai bisogno?"
                      iconLeft={
                        <img src={heartIcon} height="24px" width="24px" />
                      }
                      iconRight={
                        <div tw="h-9 w-9 rounded-full bg-primary-500 flex items-center justify-center ml-auto pl-1 flex-shrink-0">
                          <img
                            src={search}
                            height="14px"
                            width="14px"
                            tw="mr-1"
                          />
                        </div>
                      }
                    >
                      {services.map(service => (
                        <Listbox.Option
                          key={service.value}
                          value={service}
                          tw="p-2 hover:bg-gray-50 cursor-pointer border-b border-gray-100"
                        >
                          {service.description}
                        </Listbox.Option>
                      ))}
                    </RoundedSelect>
                    <p tw="text-sm font-bold text-gray-600 px-4 my-3">
                      {!selectedService &&
                        navigatedToQuoteStep1 &&
                        !showServiceError && (
                          <p tw="text-center text-error-400 transform -translate-y-1">
                            Si prega di selezionare il servizio
                          </p>
                        )}
                      {selectedService && selectedService?.longDescription}
                    </p>
                  </div>
                  <div>
                    <RoundedSelect
                      error={showCityError ? 'Seleziona città' : null}
                      value={
                        typeof selectedCity === 'string'
                          ? selectedCity.charAt(0).toUpperCase() +
                            selectedCity.slice(1)
                          : selectedCity
                      }
                      onSelect={v => {
                        setShowCityError(false);

                        analytics.submitEvent<'r-city_selected-p'>(
                          'r-city_selected-p',
                          {
                            location: 'm-qf-hero_dropdown',
                          }
                        );

                        setSelectedCity(v);
                      }}
                      placeholder="Seleziona la città più vicina"
                      iconLeft={
                        <img src={homePin} height="24px" width="24px" />
                      }
                      iconRight={
                        <div tw="h-9 w-9 rounded-full bg-primary-500 flex items-center justify-center ml-auto pl-1 flex-shrink-0">
                          <img
                            src={search}
                            height="14px"
                            width="14px"
                            tw="mr-1"
                          />
                        </div>
                      }
                    >
                      {SUPPORTED_CITIES.map(city => (
                        <Listbox.Option
                          key={city.name}
                          value={city.name}
                          tw="p-2 hover:bg-gray-50 cursor-pointer border-b border-gray-100"
                        >
                          {city.name.charAt(0).toUpperCase() +
                            city.name.slice(1)}
                        </Listbox.Option>
                      ))}
                    </RoundedSelect>
                  </div>
                </div>
                <div tw="pt-5" ref={smallScreenRef}>
                  <Button
                    fullWidth={true}
                    onClick={() => startReservation(false)}
                    variant="filled"
                    color="primary"
                  >
                    Calcola preventivo
                  </Button>
                </div>
                <div tw="flex items-center pt-3 pb-2 px-4">
                  <div tw="h-0.5 bg-primary-500 flex-1"></div>
                  <div tw="flex-shrink-0 px-2 text-primary-500 pb-0.5 font-semibold">
                    oppure
                  </div>
                  <div tw=" flex-1 h-0.5 bg-primary-500"></div>
                </div>
                <div tw="pb-3">
                  <p tw="font-bold text-center my-0">
                    Hai bisogno subito di UGO?
                  </p>
                  <div tw="flex justify-center">
                    <Button
                      size="small"
                      fullWidth
                      variant="outlined"
                      color="primary"
                      onClick={() => startReservation(true)}
                    >
                      <span tw="underline uppercase text-sm inline-block py-2">
                        Prenota per domani
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainHeader;
