import React from 'react';
import 'twin.macro';
import Button from '../../core/buttons/Button';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { getAppRedirectUri, HOSTNAME } from '../../utils/getAppRedirectUri';
import { UgoFeaturesQuery } from 'generated/graphql-types';
import Heading from '../../../components/core/typography/Heading';
import Image from 'gatsby-image';
import { Container } from '../../core/layout/Layout';
import useDatalayer from '../../../../hooks/use-datalayer';
import { useAnalytics } from '../../../analytics/AnalyticsProvider';

const Features = () => {
  const [appLocation, setAppLocation] = React.useState<string>(
    '//app.' + HOSTNAME
  );

  const datalayer = useDatalayer();
  const analytics = useAnalytics();

  React.useEffect(() => {
    setAppLocation(getAppRedirectUri());
  }, []);

  const features = useStaticQuery<UgoFeaturesQuery>(graphql`
    query UgoFeatures {
      f1: file(relativePath: { eq: "ugo_feature_1.png" }) {
        childImageSharp {
          fixed(height: 150) {
            ...GatsbyImageSharpFixedFragment
          }
        }
      }
      f2: file(relativePath: { eq: "ugo_feature_2.png" }) {
        childImageSharp {
          fixed(height: 150) {
            ...GatsbyImageSharpFixedFragment
          }
        }
      }
      f3: file(relativePath: { eq: "ugo_feature_3.png" }) {
        childImageSharp {
          fixed(height: 150) {
            ...GatsbyImageSharpFixedFragment
          }
        }
      }
    }
  `);

  return (
    <div>
      <Container>
        <div tw="flex flex-wrap md:flex-nowrap md:space-x-20 space-y-20 md:space-y-0 mb-20">
          <div tw="w-full md:w-1/3">
            <Heading variant="h4" tw="text-center">
              UGO di fiducia
            </Heading>
            <p tw="text-paragraph text-center mb-4">
              Ti affianca sempre il tuo
              <br /> operatore UGO di fiducia
            </p>
            <div tw="flex justify-center">
              <Image fixed={features.f1.childImageSharp.fixed} />
            </div>
          </div>
          <div tw="w-full md:w-1/3">
            <Heading variant="h4" tw="text-center">
              Aiuto in 24 ore
            </Heading>
            <p tw="text-paragraph text-center mb-4">
              Operatori disponibili 7/7
              <br />
              con 24 di preavviso
            </p>
            <div tw="flex justify-center">
              <Image fixed={features.f2.childImageSharp.fixed} />
            </div>
          </div>
          <div tw="w-full md:w-1/3">
            <Heading variant="h4" tw="text-center">
              Pagamento sicuro
            </Heading>
            <p tw="text-paragraph text-center mb-4">
              Pagamento online
              <br />
              diretto e tracciato.
            </p>
            <div tw="flex justify-center">
              <Image fixed={features.f3.childImageSharp.fixed} />
            </div>
          </div>
        </div>
        <div tw="flex justify-center mb-4">
          <Link
            to="/#hero"
            onClick={() => {
              datalayer.triggerReservationIntent({ action: 'values' });
              datalayer.qf4Events.reservationIntent('middleHome');
              analytics.submitEvent<'r-intent-p'>('r-intent-p', {
                location: 'm-qf-features_button',
              });
            }}
          >
            <Button variant="filled" color="primary" tw="px-20!">
              Prenota ora
            </Button>
          </Link>
        </div>
        <div tw="text-center">
          <Link
            tw="text-primary-500 underline font-bold"
            to="/protocollo-sicurezza-covid19/"
          >
            {'Protocollo di sicurezza Covid19 >'}
          </Link>
        </div>
      </Container>
    </div>
  );
};

export default Features;
