import { useAuth } from './use-auth';

enum ContentfulDatalayerAction {
  None = 'None',
  PrenotazioneStarted = 'Prenotazione Started',
  RegistrationStarted = 'Registration Started',
  PreventivoStarted = 'Preventivo Started',
  ClickLandingOperatore = 'Click Landing Operatore',
  ClickPhoneLanding = 'Click Phone Landing',
  PrenotazioneStartedLanding = 'Prenotazione Started Landing',
}

let w: any;

if (typeof window !== `undefined`) {
  w = window;
}

const allowedEvents = [
  'quote_request_complete',
  'login',
  'sign_up',
  'logout',
  'user_data_complete',
  'begin_checkout',
  'add_payment_info',
  'purchase',
  'funnel_started',
  'newsletter_subscription_complete',
  'tab_switched',
  'purchase_again',
  'reservation_cancelled',
  'mgm',
];

const useDatalayer = () => {
  const auth = useAuth();

  const sendDataToGtm = data => {
    const dataWithUserId: any = {};

    if (auth.user) {
      dataWithUserId.user_id = auth.user.uid;
      // TODO: Add role
    }

    if (allowedEvents.includes(data.event)) {
      w.dataLayer.push({
        ...dataWithUserId,
        ...data,
      });
    }
  };

  const triggerReservationStarted = (cta: 'prenota' | 'preventivo') => {
    sendDataToGtm({
      event: 'prenotazioneStarted',
      CTA: cta,
    });
  };

  const triggerSubscribeToNews = () => {
    sendDataToGtm({
      event: 'bannerNewsletter',
    });
  };

  const triggerReservationIntent = ({
    action,
  }: {
    action: 'navbar' | 'values' | 'footer';
  }) => {
    sendDataToGtm({
      event: 'prenotazioneIntent',
      action,
    });
  };

  const triggerReservationWelfareStateOfferEmailSubmitted = ({ action }) => {
    sendDataToGtm({
      event: 'reservation_welfare_state_offer_email_submitted-patient',
      action,
    });
  };

  const triggerLoginStarted = () => {
    sendDataToGtm({
      event: 'loginStarted',
    });
  };

  const triggerEnterIntent = () => {
    sendDataToGtm({
      event: 'enterIntent',
    });
  };

  const triggerRegistrationStarted = () => {
    sendDataToGtm({
      event: 'registrazioneStarted',
    });
  };

  const triggerClickBannerOperatore = () => {
    sendDataToGtm({
      event: 'clickBannerOperatore',
    });
  };

  const triggerClickLandingOperatore = () => {
    sendDataToGtm({
      event: 'clickLandingOperatore',
    });
  };

  const triggerPrenotazioneStartedLanding = ({ ab_variant_id }) => {
    sendDataToGtm({
      event: 'prenotazioneStartedLanding',
      'Page URL': window.location.href,
      ab_variant_id: ab_variant_id || '',
    });
  };

  const triggerClickPhoneLanding = ({ ab_variant_id }) => {
    sendDataToGtm({
      event: 'clickPhoneLanding',
      'Page URL': window.location.href,
      ab_variant_id: ab_variant_id || '',
    });
  };

  const triggerSubmitEmailHospitalPromotion = ({ ab_variant_id }) => {
    sendDataToGtm({
      event: 'submitEmailHospitalPromotion',
      'Page URL': window.location.href,
      ab_variant_id: ab_variant_id || '',
    });
  };

  // TODO: Move to sendDataToGtm function
  const flattenObject = (obj, prefix = '') => {
    return Object.keys(obj).reduce((acc, k) => {
      const pre = prefix.length ? prefix + '_' : '';
      if (typeof obj[k] === 'object')
        Object.assign(acc, flattenObject(obj[k], pre + k));
      else acc[pre + k] = obj[k];
      return acc;
    }, {});
  };

  // TODO: Move to sendDataToGtm function
  const transformEventParametersToEventLabelString = (params: any) => {
    return Object.entries(params)
      .map(([key, value]) => `${key}::${value}`)
      .join(';');
  };

  const triggerJobApplicationSubmittedDriver = () => {
    sendDataToGtm({
      event: 'job_application_submitted-driver',
      category: 'job_application_submitted-driver',
      action: 'typeform',
    });
  };

  const triggerJobApplicationStartedDriver = ({
    action,
  }: {
    action:
      | 'navbar_button_1'
      | 'header_button_1'
      | 'green_banner_button_1'
      | 'faq_button_1'
      | 'yellow_banner_bottom_button_1';
  }) => {
    const _action =
      action === 'navbar_button_1'
        ? 'driver_onboarding_flow-navbar_button_1'
        : action === 'header_button_1'
        ? 'driver_onboarding_flow-header_button_1'
        : action === 'green_banner_button_1'
        ? 'driver_onboarding_flow-green_banner_button_1'
        : action === 'faq_button_1'
        ? 'driver_onboarding_flow-faq_button_1'
        : action === 'yellow_banner_bottom_button_1'
        ? 'driver_onboarding_flow-yellow_banner_bottom_button_1'
        : action;

    sendDataToGtm({
      event: 'job_application_started-driver',
      category: 'job_application_started-driver',
      action: _action,
      // label: transformEventParametersToEventLabelString(
      //   flattenObject({
      //     // TODO: Add role
      //   })
      // ),
    });
  };

  const triggerContentfulDatalayerAction = (action: string, data?: any) => {
    switch (action) {
      case ContentfulDatalayerAction.PrenotazioneStarted: {
        triggerReservationStarted('prenota');
        break;
      }
      case ContentfulDatalayerAction.PreventivoStarted: {
        triggerReservationStarted('preventivo');
        break;
      }
      case ContentfulDatalayerAction.RegistrationStarted: {
        triggerRegistrationStarted();
        break;
      }
      case ContentfulDatalayerAction.ClickLandingOperatore: {
        triggerClickLandingOperatore();
        break;
      }
      case ContentfulDatalayerAction.PrenotazioneStartedLanding: {
        triggerPrenotazioneStartedLanding(data);
        break;
      }
      case ContentfulDatalayerAction.ClickPhoneLanding: {
        triggerClickPhoneLanding(data);
        break;
      }
      default: {
        break;
      }
    }
  };

  const triggerReservationServiceChoice = (service: string) => {
    sendDataToGtm({
      event: 'prenotazioneServiceChoice',
      label: service,
    });
  };

  const triggerReservationZipChoice = (zip: string) => {
    sendDataToGtm({
      event: 'prenotazioneZipChoice',
      label: zip,
    });
  };

  /**
   * These are only temporary events until new datalayer architecture is setup
   * https://app.clickup.com/t/861m9n8vg
   */
  const reservationIntent = (action: 'Nav' | 'middleHome' | 'Footer') => {
    sendDataToGtm({
      event: `prenotationIntent_${action}`,
    });
  };

  const serviceSelected = () => {
    sendDataToGtm({
      event: `qf4_ServiceSelected`,
    });
  };

  const zipCodeEntry = () => {
    sendDataToGtm({
      event: `qf4_ZipcodeEntry`,
    });
  };

  const reservationStartedMaster = () => {
    sendDataToGtm({
      event: `qf4_prenotationStarted_master`,
    });
  };

  const reservationStartedForTomorrow = () => {
    sendDataToGtm({
      event: `qf4_prenotationStarted_tomorrow`,
    });
  };

  const triggerFunnelStarted = (path: string) => {
    sendDataToGtm({
      event: 'funnel_started',
      starting_location: path,
    });
  };

  const triggerNewSubscriptionComplete = () => {
    sendDataToGtm({
      event: 'newsletter_subscription_complete',
    });
  };

  const triggerTabSwitched = (tabName: string) => {
    sendDataToGtm({
      event: 'tab_switched',
      tab_name: tabName,
    });
  };

  return {
    triggerReservationStarted,
    triggerSubscribeToNews,
    triggerLoginStarted,
    triggerRegistrationStarted,
    triggerContentfulDatalayerAction,
    triggerEnterIntent,
    triggerClickBannerOperatore,
    triggerClickPhoneLanding,
    triggerPrenotazioneStartedLanding,
    triggerSubmitEmailHospitalPromotion,
    triggerJobApplicationStartedDriver,
    triggerJobApplicationSubmittedDriver,
    triggerReservationWelfareStateOfferEmailSubmitted,
    triggerReservationIntent,
    triggerReservationServiceChoice,
    triggerReservationZipChoice,
    // Temporary
    qf4Events: {
      reservationIntent,
      serviceSelected,
      zipCodeEntry,
      reservationStartedMaster,
      reservationStartedForTomorrow,
    },
    v3: {
      triggerNewSubscriptionComplete,
      triggerTabSwitched,
      triggerFunnelStarted,
    },
  };
};

export default useDatalayer;
