import Heading from '../../core/typography/Heading';
import InputWithButton from '../../core/form-controls/InputWithButton/InputWithButton';
import React, { PropsWithChildren, useState } from 'react';
import Button from '../../core/buttons/Button';
import RoundedTextField from '../../core/form-controls/RoundedTextField/RoundedTextField';
import tw from 'twin.macro';
import { subscribeToNewsletter } from './newsletter-subscribe';
import UgoCheckbox from '../../core/form-controls/Checkbox/UgoCheckbox';
import useDatalayer from '../../../../hooks/use-datalayer';
import { collectPromotionEmail } from './collect-promotion-email';
import { saveContactToWelfareLeadGenerationList } from './collect-email-welfare-lead-generation';

const TOS_ERROR = 'Accetta le condizioni di marketing di seguito';
const INVALID_EMAIL_ERROR = 'Per favore, inserisci un indirizzo email valido.';
const REQUEST_ERROR =
  'Ops! Qualcosa è andato storto, per favore riprova più tardi.';

export const TermsComponent = (props: PropsWithChildren<any>) => props.children;
export const SuccessComponent = (props: PropsWithChildren<any>) =>
  props.children;

const CollectEmail = ({
  offsetTerms,
  children,
  buttonLabel = 'Iscriviti',
  autoConsent = false,
  type = 'newsletter',
  abVariant,
  eventAction,
}: PropsWithChildren<{
  offsetTerms?: boolean;
  buttonLabel?: string;
  autoConsent?: boolean;
  abVariant?: any;
  type?: 'newsletter' | 'promotion' | 'welfare_lead';
  eventAction?: string;
}>) => {
  const emailValidationRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const datalayer = useDatalayer();
  const [email, setEmail] = useState<string>();
  const [error, setError] = useState<string>();
  const [submitSuccess, setSubmitSuccess] = useState<boolean>();
  const [submitted, setSubmitted] = useState<boolean>();
  const [loading, setLoading] = useState<boolean>(false);
  const [agreeTOS, setAgreeTOS] = useState<boolean>(autoConsent);
  const [phoneNumber, setPhoneNumber] = useState<string>('');

  const submit = () => {
    setSubmitted(true);
    if (!agreeTOS) {
      setError(TOS_ERROR);
      return;
    }
    if (emailValidationRegex.test(email)) {
      setError(null);
      setLoading(true);
      switch (type) {
        case 'welfare_lead': {
          saveContactToWelfareLeadGenerationList({ email, phoneNumber })
            .then(() => {
              setLoading(false);

              datalayer.triggerReservationWelfareStateOfferEmailSubmitted({
                action: eventAction,
              });

              setSubmitSuccess(true);
            })
            .catch(() => setError(REQUEST_ERROR));
          break;
        }
        case 'newsletter': {
          subscribeToNewsletter({ email })
            .then(() => {
              setLoading(false);

              datalayer.triggerSubscribeToNews();
              datalayer.v3.triggerNewSubscriptionComplete();

              setSubmitSuccess(true);
            })
            .catch(() => setError(REQUEST_ERROR));
          break;
        }
        case 'promotion': {
          collectPromotionEmail({ email, ab_variant_id: abVariant })
            .then(() => {
              setLoading(false);

              datalayer.triggerSubmitEmailHospitalPromotion({
                ab_variant_id: abVariant,
              });

              setSubmitSuccess(true);
            })
            .catch(() => setError(REQUEST_ERROR));
          break;
        }
      }
    } else {
      setError(INVALID_EMAIL_ERROR);
    }
  };

  const updateField = (val: string) => {
    setEmail(val);
    setError(emailValidationRegex.test(email) ? null : INVALID_EMAIL_ERROR);
  };

  return (
    <div tw="relative">
      <div
        css={[
          tw`opacity-100 transition transform duration-300 z-20`,
          submitSuccess && tw`opacity-0 -translate-y-10`,
        ]}
      >
        {/*<div tw="hidden md:block">*/}
        {/*  <InputWithButton*/}
        {/*    fullWidth*/}
        {/*    onChange={val => updateField(val.target.value)}*/}
        {/*    submit={submit}*/}
        {/*    disabled={loading}*/}
        {/*    type="email"*/}
        {/*    buttonText={buttonLabel}*/}
        {/*    placeholder="Inserisci qui la tua email"*/}
        {/*    error={submitted ? error : null}*/}
        {/*    css={[submitted && error?.length && tw`text-error-500`]}*/}
        {/*  />*/}
        {/*</div>*/}
        <div tw="mx-auto">
          <div tw="mb-3">
            <RoundedTextField
              fullWidth
              type="email"
              onChange={val => updateField(val.target.value)}
              placeholder="Inserisci qui la tua email"
              css={[submitted && error?.length && tw`text-error-500`]}
            />
            {/*{type === 'welfare_lead' ? (*/}
            {/*  <div tw="mt-3">*/}
            {/*    <RoundedTextField*/}
            {/*      fullWidth*/}
            {/*      type="number"*/}
            {/*      onChange={e => setPhoneNumber(e.target.value.toString())}*/}
            {/*      placeholder="Numero di telefono"*/}
            {/*      css={[submitted && error?.length && tw`text-error-500`]}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*) : null}*/}
          </div>
        </div>
        <div tw="md:w-1/2 mx-auto mb-3 md:mb-6">
          <Button
            variant="filled"
            color="primary"
            disabled={loading}
            onClick={submit}
            fullWidth
          >
            {buttonLabel}
          </Button>
          {submitted && error?.length && (
            <p tw="text-center mb-0 text-error-500 pt-2">{error}</p>
          )}
        </div>
        <div tw="flex justify-center" css={[offsetTerms && tw`pr-28 lg:pr-0`]}>
          {/* @ts-ignore */}
          {(children?.length ? children : [children])?.find(
            (child: any) => child?.type === TermsComponent
          ) || (
            <UgoCheckbox
              onChange={val => setAgreeTOS(val.target.checked)}
              name="tos3"
              color="primary"
              label={
                <div tw="text-left!">
                  Acconsento al trattamento dati per le finalità di{' '}
                  <a
                    target="_blank"
                    tw="bg-primary-500 cursor-pointer"
                    href="https://hellougo.com/trattamento-dati-fini-commerciali/"
                  >
                    marketing
                  </a>{' '}
                  <br />I tuoi dati non verranno mai passati a terzi.
                </div>
              }
            />
          )}
        </div>
      </div>
      <div
        css={[
          tw`opacity-0 w-full transition transform duration-300 absolute top-1/2 translate-y-1/2 invisible`,
          submitSuccess && tw`opacity-100 -translate-y-10 visible`,
        ]}
      >
        {/* @ts-ignore */}
        {(children?.length ? children : [children])?.find(
          (child: any) => child?.type === SuccessComponent
        ) || (
          <>
            <Heading variant="h5" tw="mb-0 text-center">
              Grazie di esserti iscritto alla nostra Newsletter.
            </Heading>
            <p tw="text-paragraph text-center">
              Riceverai ogni settimana aggiornamenti e offerte dal mondo UGO.
              Siamo felici di darti il benvenuto!
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default CollectEmail;
