import { Link } from 'gatsby';
import React, { useState } from 'react';
import tw, { styled } from 'twin.macro';
import { css } from '@emotion/css';
import { NavLink } from './nav-links';
import { Transition } from '@headlessui/react';
import useDatalayer from '../../../../../hooks/use-datalayer';

interface NavbarItemProps {
  link: NavLink;
  appLocation: string;
}

const StyledNavbarItem = styled(Link)(() => [tw`font-bold text-paragraph`]);
const StyledNavbarItemAnchor = styled.a(() => [tw`font-bold text-paragraph`]);

const NavbarItem = ({ link, appLocation }: NavbarItemProps) => {
  const [subOpen, setSubOpen] = useState<boolean>(false);
  const datalayer = useDatalayer();

  // For @react/headlessui currently an additional 5kB library @emotion/css
  // Is needed to create new classnames in order for tailwinds transition to work
  // Working emotion https://codesandbox.io/s/next-styled-components-tailwind-twin-starter-headless-ui-transition-forked-p7hbe?file=/pages/index.js:54-83
  // https://github.com/tailwindlabs/headlessui/issues/80
  const twClassNames = {
    enter: css(tw`transition ease-out duration-100 transform`),
    enterFrom: css(tw`opacity-0 scale-95`),
    enterTo: css(tw`opacity-100 scale-100`),
    leave: css(tw`transition ease-in duration-75 transform`),
    leaveFrom: css(tw`opacity-100 scale-100`),
    leaveTo: css(tw`opacity-0 scale-95`),
  };

  return (
    <div
      tw="relative"
      onMouseEnter={() => setSubOpen(true)}
      onMouseLeave={() => setSubOpen(false)}
    >
      {link.redirectToApp ? (
        <StyledNavbarItemAnchor href={appLocation + link.url} tw="px-3 py-3">
          {link.name}
        </StyledNavbarItemAnchor>
      ) : (
        <StyledNavbarItem tw="px-3 py-3" to={link.url}>
          {link.name}
        </StyledNavbarItem>
      )}

      <Transition show={subOpen} {...twClassNames}>
        {link.children ? (
          <div
            tw="absolute z-10 -ml-4 transform px-2 sm:px-0 lg:ml-0"
            style={{ minWidth: '230px' }}
          >
            <div tw="rounded-md border-t-4 border-primary-500 shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div tw="relative bg-white px-5 py-6 sm:p-3">
                {link.children.map(c => (
                  <Link
                    key={c.name}
                    to={c.url}
                    tw="hover:bg-gray-200 rounded-md p-2 cursor-pointer block"
                  >
                    {c.name}
                  </Link>
                ))}
              </div>
            </div>
          </div>
        ) : null}
      </Transition>
    </div>
  );
};

export default NavbarItem;
