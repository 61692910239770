import React, { ReactNode } from 'react';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import tw from 'twin.macro';
import uniqueId from 'lodash/uniqueId';

interface UgoCheckboxProps extends CheckboxProps {
  error?: string;
  label?: ReactNode;
}

const UgoCheckbox = ({ ...props }: UgoCheckboxProps) => {
  const id = uniqueId();
  return (
    <>
      <div tw="flex items-center">
        {/*@ts-ignore*/}
        <Checkbox
          id={id}
          inputProps={{ 'aria-label': 'decorative checkbox' }}
          {...props}
        />
        <div>
          <label htmlFor={id} tw="cursor-pointer">
            {props.label}
          </label>
          {props.error && (
            <div>
              <span css={[tw`text-error-500 block pt-1 text-left`]}>
                {props.error}
              </span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default UgoCheckbox;
