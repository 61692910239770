import React, { createContext, PropsWithChildren, useContext } from 'react';
import _submitEvent, { gtmEvent, GTMEventParams } from './submitEvent';

interface AnalyticsContextProps {
  user: any;
  // Static parameters used to enrich all events
  staticParameters?: { [key: string]: any };
}

const AnalyticsContext = createContext<AnalyticsContextProps>({
  user: null,
  staticParameters: {},
});
const { Provider } = AnalyticsContext;

export default function AnalyticsProvider({
  children,
  user,
  staticParameters = {},
}: PropsWithChildren<AnalyticsContextProps>) {
  return <Provider value={{ user, staticParameters }}>{children}</Provider>;
}

export const useAnalytics = () => {
  const context = useContext(AnalyticsContext);

  if (context === undefined) {
    throw new Error('useAnalytics must be used within a AnalyticsProvider');
  }

  const submitEvent = <T extends gtmEvent>(
    event: gtmEvent,
    parameters: GTMEventParams<T>
  ) => {
    return;
    // _submitEvent(event, {
    //   location: parameters.location,
    //   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //   // @ts-ignore
    //   parameters: {
    //     ...parameters.parameters,
    //     ...context.staticParameters,
    //   },
    //   user: context.user,
    // });
  };

  return {
    submitEvent,
  };
};
