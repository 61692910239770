import React from 'react';
import Container from '../../core/layout/Layout/Container';
import 'twin.macro';
import Heading from '../../core/typography/Heading';
import { graphql, useStaticQuery } from 'gatsby';
import { OrangeBannerQuery } from '../../../../generated/graphql-types';
import Image from 'gatsby-image';

const PromoBanner = () => {
  const data = useStaticQuery<OrangeBannerQuery>(graphql`
    query OrangeBanner {
      image: file(relativePath: { eq: "fast_grandma.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            presentationHeight
            presentationWidth
            ...GatsbyImageSharpFluidFragment
          }
        }
      }
    }
  `);
  return (
    <div tw="pt-20">
      <div
        tw="py-20 lg:py-6"
        style={{
          backgroundImage:
            'linear-gradient(-268deg, #f0a621 0%, #ffdf6e 98%, #ffdf6e 100%)',
        }}
      >
        <Container>
          <div tw="flex relative items-center justify-center flex-wrap">
            <div tw="w-full lg:w-3/4 z-10">
              <Heading
                variant="h3"
                tw="text-white text-center md:text-left italic text-6xl mb-0"
              >
                “Gli ostacoli quotidiani si
                <br tw="hidden lg:block" />
                superano con l’aiuto giusto”
              </Heading>
            </div>
            <div tw="pt-10 lg:pt-0 w-1/2 lg:w-1/4 md:transform lg:scale-110 lg:-translate-y-10">
              <Image tw="w-full" fluid={data.image.childImageSharp.fluid} />
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default PromoBanner;
